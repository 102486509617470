import React from "react";
import {
  Button,
  Card,
  Table,
  Col
} from "react-bootstrap";
import WebRegisterDialog from "./WebRegisterDialog";
import BatchModalDialog from "../../components/BatchModalDialog";
import WebPageTable from "./WebPageTable";
import { 
  createWeb, 
  listWebs, 
  updateResource,
  updateWeb,
  requestProcessingResource,
  requestDeletingResource,
  deleteResource
} from "../../apis/ResourceApi";
import moment from "moment";
import "./CommonTable.css";
import { useSelector } from 'react-redux';
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import { BUTTON_LABELS, MESSAGES, RESOURCE_STATUS_LABELS } from "../../lang/ja";
import { EXECUTION_TYPES, PROCESS_TYPES, RESOURCE_STATUS } from "../../constants/status";
import FanLoadingIcon from "components/FanLoadingIcon";
import RejectResourceDialog from "pages/resource/RejectResourceDialog";
import PaginationComponent from "components/PaginationComponent";
import { useDialog } from "../../contexts/DialogContext";
import { NUM_ITEMS_PER_PAGE } from "../../constants/table";
import { WEEKDAYS } from "constants/resource";
import { getStatusLabel } from "utils/resources";
import { getProcessTime } from "utils/resources";

const DAYTIME = 24 * 3600000;
const WEEKTIME = 7 * DAYTIME;
function WebTable () {
  /***** States and Variables *****/
  const [selectedWeb, setSelectedWeb] = React.useState(null);
  const [selectedWebForBatch, setSelectedWebForBatch] = React.useState(null);
  const [editedWeb, setEditedWeb] = React.useState(null);
  const [webs,setWebs] = React.useState([]);
  const { userInfo } = useSelector((state) => state.users)
  const governmentId = userInfo?.governmentId;
  const nextTokenRef = React.useRef(null);
  const [curPage, setCurPage] = React.useState(0);
  const numPages = Math.ceil(webs.length/NUM_ITEMS_PER_PAGE);
  const [loading, setLoading] = React.useState(false);
  const [rejectedWeb, setRejectedWeb] = React.useState(null);
  const { error, success } = useDialog();

  const handleSetScheduled = (web) => {
    setSelectedWebForBatch(web);
  };
  
  /***** Processing *****/
  React.useEffect(()=>{
    const downloadWebs = async () => {
      const res = await listWebs(governmentId, APP_TYPE===APP_TYPES.OUTSIDE_ADMIN, null);
      if (res.success) {
        setWebs(res.data);
        nextTokenRef.current = res.nextToken;
      }
    };
    if (governmentId) downloadWebs();
  }, [governmentId])

  /***** Event Handlers *****/
  const onRegisterWeb = async (url) => {
    if (loading) return;
    setLoading(true);
    console.log("editedWeb",editedWeb)
    if (!editedWeb.governmentId) {
      const res = await createWeb(governmentId, url, APP_TYPE===APP_TYPES.OUTSIDE_ADMIN);
      if (res.success) {
        setWebs([res.data, ...webs]);
      }
      else {
        const err = res.error===MESSAGES.URL_ALREADY_REGISTERED? MESSAGES.URL_ALREADY_REGISTERED : MESSAGES.SOMETHING_WRONG;
        error(err);
      }
    }
    else {
      const res = await updateWeb(editedWeb, url);
      if (res.success) {
        const pos = webs.indexOf(editedWeb);
        webs.splice(pos, 1, res.data);
        setWebs(Object.assign([], webs));
      }
    }
    setEditedWeb(null);
    setLoading(false);
  }

  const onPageChange = async (page) => {
    setCurPage(page);
    if (page+1===numPages) {
      const nextToken = nextTokenRef.current;
      if (nextToken) {
        const res = await listWebs(governmentId, APP_TYPE===APP_TYPES.OUTSIDE_ADMIN, nextToken);
        if (res.success) {
          setWebs([...webs, ...res.data]);
          nextTokenRef.current = res.nextToken;
        }
      }
    }
  }
  const onRequestProcess = async (web) => {
    if (loading) return;
    setLoading(true);
    const res = await requestProcessingResource(web);
    if (res.success) {
      const pos = webs.indexOf(web);
      if (pos>=0) {
        webs.splice(pos, 1, res.data);
        setWebs(Object.assign([], webs));
      }
    }
    setLoading(false);
  }
  const onRequestDelete = async (web) => {
    if (loading) return;
    setLoading(true);
    const res = await requestDeletingResource(web);
    if (res.success) {
      const pos = webs.indexOf(web);
      if (pos>=0) {
        webs.splice(pos, 1, res.data);
        setWebs(Object.assign([], webs));
      }
    }
    setLoading(false);
  }
  const onDeleteWeb = async (web) => {
    if (loading) return;
    setLoading(true);
    const res = await deleteResource(web);
    if (res.success) {
      const pos = webs.indexOf(web);
      if (pos>=0) {
        webs.splice(pos, 1);
        setWebs(Object.assign([], webs));
      }
    }
    setLoading(false);
  }
  const onUpdateResourceExecution = async (executionType, executionDetail) => {
    if (loading) return;
    setLoading(true);
    const web = selectedWebForBatch;
    const res = await updateResource(web, {execution: executionType, executionDetail});
    if (res.success) {
        success(MESSAGES.EXECUTION_UPDATED_SUCCESSFULLY);
        webs.splice(webs.indexOf(web), 1, res.data);
        setWebs(Object.assign([], webs));
    }
    else {
        error(MESSAGES.SOMETHING_WRONG);
    }
    setSelectedWebForBatch(null);
    setLoading(false);
  }
  /***** Sub Components *****/
  /***** Main Render *****/
  if (selectedWeb) {
    console.log("selectedWeb",selectedWeb)
    return <WebPageTable 
    web={selectedWeb}
    onBack={()=>{setSelectedWeb(null)}}
    isAdminTable={false}/>
  }
  const editedWebStatus = editedWeb?.status;
  return <>
    <Col md="12">
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">
            データ管理 &gt; WEBサイト
          </Card.Title>
          <div className="d-flex flex-row justify-content-between align-items-end">
            <PaginationComponent 
              currentPage={curPage}
              totalItems={webs.length}
              onPageChange={onPageChange}/>
            <Button
              className="btn-outline btn-wd mr-1"
              variant="default"
              onClick={()=>{setEditedWeb({})}}>
              {BUTTON_LABELS.BUTTON_REGISTER_NEW}
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="table-responsive p-0">
          <Table className="table-hover table-striped w-full">
            <thead>
              <tr>
                <th className="common_table_name_cell">URL</th>
                <th>作成日時</th>
                <th>反映予定日時</th>
                <th>ステータス</th>
                <th>アクション</th>
              </tr>
            </thead>
            <tbody>
              {webs.map((web, i)=>{
                if (i<curPage*NUM_ITEMS_PER_PAGE || i>=(curPage+1)*NUM_ITEMS_PER_PAGE) return null;
                const status = web.status;
                const editable = status===RESOURCE_STATUS.PENDING || status===RESOURCE_STATUS.PROCESS_REJECTED;
                return <tr>
                  <td
                  style={{
                    cursor: 'pointer',
                    lineBreak: 'anywhere'
                  }}
                  onClick={()=>{setSelectedWeb(web)}}>
                    {web.name}
                  </td>
                  <td>{moment(web.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                  <td>{getProcessTime(web)}</td>
                  <td>
                    {getStatusLabel(web)}
                    {web.rejectReason &&
                    <button 
                    className="button_reject_reason"
                    onClick={()=>{setRejectedWeb(web)}}>
                      !
                    </button>}
                  </td>
                  <td><>
                    {/**** EDIT OR DETAIL ****/}
                    <Button
                      className="btn-outline mr-1"
                      variant="default"
                      onClick={()=>{setEditedWeb(web)}}>
                      {editable? BUTTON_LABELS.BUTTON_EDIT: BUTTON_LABELS.BUTTON_DETAIL}
                    </Button>
                    {/**** REQUEST PROCESS ****/}
                    {status===RESOURCE_STATUS.PENDING &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onRequestProcess(web)}}>
                        {BUTTON_LABELS.BUTTON_REQUEST_PROCESS}
                      </Button>}
                    {/**** REQUEST DELETE ****/}
                    {status===RESOURCE_STATUS.FINISHED &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onRequestDelete(web)}}>
                        {BUTTON_LABELS.BUTTON_REQUEST_DELETE}
                      </Button>}
                    {/**** DELETE ****/}
                    {(status===RESOURCE_STATUS.PENDING || status===RESOURCE_STATUS.PROCESS_REQUESTING || status===RESOURCE_STATUS.PROCESS_REJECTED) &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onDeleteWeb(web)}}>
                        {BUTTON_LABELS.BUTTON_DELETE}
                      </Button>}
                       {/***** SET SCHEDULED *****/}
                       <Button
                      className="btn-outline mr-1"
                      variant="default"
                      onClick={()=>{handleSetScheduled(web)}}>
                        {BUTTON_LABELS.BUTTON_SET_SCHEDULED}
                      </Button>
                  </></td>
                </tr>
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
    <BatchModalDialog 
        isOpen={!!selectedWebForBatch}
        isCreateDialog={selectedWebForBatch?.status===RESOURCE_STATUS.FINISHED}
        onClose={()=>{setSelectedWebForBatch(null)}}
        enableRecurring={true}
        onSubmit={onUpdateResourceExecution}
        initExecutionType={selectedWebForBatch?.execution}
        initExecutionDetail={selectedWebForBatch?.executionDetail}
        editable={selectedWebForBatch?.status!==RESOURCE_STATUS.PROCESSING}/>
    <WebRegisterDialog
    isOpen={editedWeb}
    isNew={!editedWeb?.id}
    onClose={()=>{setEditedWeb(null)}}
    onRegister={onRegisterWeb}
    url={editedWeb?.name}
    editable={!editedWebStatus || editedWebStatus===RESOURCE_STATUS.PENDING || editedWebStatus===RESOURCE_STATUS.PROCESS_REJECTED}/>
    <RejectResourceDialog
    isOpen={!!rejectedWeb}
    onClose={()=>{setRejectedWeb(null)}}
    reason={rejectedWeb?.rejectReason}/>
    {loading && <FanLoadingIcon size={80}/>}
  </>
}

export default WebTable;