import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from "react-bootstrap";
import { NUM_ITEMS_PER_PAGE } from '../constants/table';

const propTypes = {
    currentPage: PropTypes.number,
    totalItems: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onPageChange: PropTypes.func
};

const defaultProps = {
    currentPage: 0,
    itemsPerPage: NUM_ITEMS_PER_PAGE,
    onPageChange: () => {}
};

const PaginationComponent = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
    const numPages = Math.ceil(totalItems / itemsPerPage);

    const onClickPage = (page) => {
        if (page >= 0 && page < numPages) {
            onPageChange(page);
        }
    };

    const paginationItems = () => {
        const items = [];

        if (numPages <= 6) {
            for (let i = 0; i < numPages; i++) {
                items.push(
                    <Pagination.Item key={i} active={i === currentPage} onClick={() => onClickPage(i)}>
                        {i + 1}
                    </Pagination.Item>
                );
            }
        } else {
            items.push(
                <Pagination.Item key={0} active={0 === currentPage} onClick={() => onClickPage(0)}>
                    1
                </Pagination.Item>
            );

            let startPage = Math.max(currentPage - 2, 1);
            let endPage = Math.min(currentPage + 2, numPages - 1);

            if (currentPage <= 2) {
                endPage = 5;
            }

            if (currentPage >= numPages - 3) {
                startPage = numPages - 5;
            }

            if (startPage > 1) {
                items.push(
                    <Pagination.Ellipsis key="start-ellipsis" />
                );
            }

            for (let i = startPage; i < endPage; i++) {
                items.push(
                    <Pagination.Item key={i} active={i === currentPage} onClick={() => onClickPage(i)}>
                        {i + 1}
                    </Pagination.Item>
                );
            }

            if (endPage < numPages - 1) {
                items.push(
                    <Pagination.Ellipsis key="end-ellipsis" />
                );
            }

            items.push(
                <Pagination.Item key={numPages - 1} active={numPages - 1 === currentPage} onClick={() => onClickPage(numPages - 1)}>
                    {numPages}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <Pagination className="pagination pagination-no-border" style={{margin: 0}}>
            <Pagination.Item onClick={() => onClickPage(currentPage - 1)} disabled={currentPage <= 0}>«</Pagination.Item>
            {paginationItems()}
            <Pagination.Item onClick={() => onClickPage(currentPage + 1)} disabled={currentPage >= numPages - 1}>»</Pagination.Item>
        </Pagination>
    );
};

PaginationComponent.propTypes = propTypes;
PaginationComponent.defaultProps = defaultProps;

export default PaginationComponent;