import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import { authApi } from '../features/user/userService'

const store = configureStore({
    reducer: {
        users: userReducer,
        [authApi.reducerPath]: authApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(authApi.middleware),
})

export default store;
