import {API, graphqlOperation} from 'aws-amplify';
import React, {useState, useEffect, useCallback} from "react";
import GovernmentForm from "../../components/GovernmentForm";
import {Card, Container, Col} from "react-bootstrap";
import {updateGovernment} from "../../graphql/mutations";
import {useHistory} from "react-router";
import paths from "../../constants/paths";
import {useParams} from "react-router-dom";
import {getGovernment} from "../../graphql/queries";
import SpinnerPage from "../../components/SpinnerPage";

const EditGovernment = () => {
    let {governmentId} = useParams();
    const [government, setGovernment] = useState(null);
    const [loading, setLoading] = useState(true);

    let history = useHistory();

    useEffect(() => {
        const fetchGovernmentDetails = async () => {
            try {

                const result = await API.graphql(graphqlOperation(getGovernment, {
                    governmentId
                }));

                setGovernment(result.data.getGovernment);
            } catch (error) {
                console.error('Error fetching government details:', error);
            }
            setLoading(false)
        };

        fetchGovernmentDetails();
    }, [governmentId]);

    const handleEditGovernment = useCallback(async (data) => {
        try {
            const { status, ...rest } = data;
    
            const result = await API.graphql(graphqlOperation(updateGovernment, {
                input: {
                    ...rest,
                    governmentId,
                    status: status ? 1 : 0
                }
            }));
    
            result.data && history.push(paths.LIST_GOVERNMENTS);
        } catch (error) {
            console.error(error);
        }
    });

    if (loading) {
        return <SpinnerPage/>;
    }

    return <Col md="12">
        <Card className="strpied-tabled-with-hover">
            <Card.Header>
                <Card.Title as="h4">自治体編集</Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-4">
                <GovernmentForm onSubmit={handleEditGovernment} government={government}/>
            </Card.Body>
        </Card>
    </Col>
}
export default EditGovernment
