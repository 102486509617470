/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "analyzingRestApi",
            "endpoint": "https://5dckq921ui.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "chatGPTLineChatBotRestApi",
            "endpoint": "https://z922rgjfa4.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "userRestApi",
            "endpoint": "https://o1h10h7yz7.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "scrapebatch",
            "endpoint": "https://ju60x17j8d.execute-api.ap-northeast-1.amazonaws.com",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://7r2ijeneivbmfie44nrglm3qhy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mqluhzfdavcr7l6adj3o4mt36y",
    "aws_cognito_identity_pool_id": "ap-northeast-1:4683ee2a-98a5-488c-ac4c-851f772ad524",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_Md0TVU8h7",
    "aws_user_pools_web_client_id": "26554ddpfvkejuppiva13cgd6j",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "town-match-storage191425-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
