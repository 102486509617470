import React from "react";
import {Switch, Route} from "react-router-dom";
import "./Admin.css";

// core components
import Sidebar from "../components/Sidebar/Sidebar.js";

// dinamically create dashboard routes
import image3 from "assets/img/full-screen-image-3.jpg";
import CreateGovernment from "../pages/governments/create";
import paths from "../constants/paths";
import CreateAccount from "../pages/account/create";
import Accounts from "../pages/account";
import Governments from "../pages/governments";
import EditGovernment from "../pages/governments/edit";
import useRoutes from "../routes";
import EditAccount from "../pages/account/edit";
import QaPage from "../pages/qa/index";

function Admin() {
    const routes = useRoutes();

    const [sidebarImage, setSidebarImage] = React.useState(image3);
    const [sidebarBackground, setSidebarBackground] = React.useState("black");
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        component={prop.component}
                        exact
                    />
                );
            } else {
                return null;
            }
        });
    };
    return (
        <>
            <div className="wrapper">
                <Sidebar
                    routes={routes}
                    image={sidebarImage}
                    background={sidebarBackground}
                />
                <div className="main-panel">
                    <div className="admin_content">
                        <Switch>
                            {getRoutes(routes)}
                            <Route exact path={paths.CREATE_GOVERNMENT} render={(props) => <CreateGovernment {...props} />}/>
                            <Route exact path={paths.CREATE_ACCOUNT} render={(props) => <CreateAccount {...props} />}/>
                            <Route exact path={paths.LIST_ACCOUNT} render={(props) => <Accounts {...props} />}/>
                            <Route exact path={paths.LIST_GOVERNMENTS} render={(props) => <Governments {...props} />}/>
                            <Route exact path={paths.EDIT_GOVERNMENT} render={(props) => <EditGovernment {...props} />}/>
                            <Route exact path={paths.EDIT_ACCOUNT} render={(props) => <EditAccount {...props} />}/>
                            <Route exact path={paths.LIST_QA} render={(props) => <QaPage {...props} />}/>
                        </Switch>
                    </div>
                    <div
                        className="close-layer"
                        onClick={() =>
                            document.documentElement.classList.toggle("nav-open")
                        }
                    />
                </div>
            </div>
        </>
    );
}

export default Admin;
