import {API, graphqlOperation} from 'aws-amplify';
import React from "react";
import GovernmentForm from "../../components/GovernmentForm";
import {Card, Col} from "react-bootstrap";
import {createGovernment} from "../../graphql/mutations";
import {v4 as uuidv4} from 'uuid';
import {GOVERNMENT_STATUS} from "../../constants/status";
import {useHistory} from "react-router";
import paths from "../../constants/paths";

const CreateGovernment = () => {

    let history = useHistory();

    const handleCreateCity = async (data) => {
        const newUuid = uuidv4();

        try {
            const result = await API.graphql(graphqlOperation(createGovernment, {
                input: {
                    ...data,
                    governmentId: newUuid,
                    status: GOVERNMENT_STATUS.active,
                    internalMsgCount: 0,
                    externalMsgCount: 0
                }
            }));
            if (result.data) history.push(paths.LIST_GOVERNMENTS)
        } catch (error) {
            console.error(error);
        }
    }

    return <Col md="12">
        <Card className="strpied-tabled-with-hover">
            <Card.Header>
                <Card.Title as="h4">自治体作成</Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-4">
                <GovernmentForm onSubmit={handleCreateCity}/>
            </Card.Body>
        </Card>
    </Col>
}
export default CreateGovernment
