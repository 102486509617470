import React, {useEffect} from "react";
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useHistory} from "react-router";
import {
    Form,
    Col, Row, Card
} from "react-bootstrap";
import {registerUser} from "../../features/user/userActions";
import {useDispatch, useSelector} from "react-redux";
import {registerAccountSchema} from "../../utils/validationSchemas";
import {useParams} from 'react-router-dom';
import AccountForm from "../../components/AccountForm";
import { APP_TYPE, APP_TYPES } from "../../constants/app";

function CreateAccount() {
    const dispatch = useDispatch();
    const history = useHistory();
    let {governmentId} = useParams();
    const {loading, error} = useSelector((state) => state.users)

    const {register, handleSubmit, formState: {errors}, control, watch, setValue} = useForm({
        resolver: yupResolver(registerAccountSchema)
    });

    const onSubmit = async data => {
        const newData = {
            ...data,
            roles: [data.role],
            role: undefined,
            enabled: true
        };

        await dispatch(registerUser({...newData, governmentId}));

        if (!error) {
            history.goBack()
        }
    }

    return (
        <Col md="12">
            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Card.Title as="h4">ユーザーアカウント作成</Card.Title>
                </Card.Header>
                <Card.Body className="table-responsive p-4">
                    <AccountForm
                        onSubmit={onSubmit}
                        defaultValues={{
                            username: '',
                            email: '',
                            password: '',
                            role: APP_TYPE===APP_TYPES.OUTSIDE_ADMIN? 'outsideAdminStaff': 'staff'
                        }}
                        resolver={registerAccountSchema}
                        loading={loading}
                        error={error}
                    />
                </Card.Body>
            </Card>
        </Col>
    );
}

export default CreateAccount;
