import { WEEKDAYS } from "../constants/resource";
import { RESOURCE_STATUS, WEBPAGE_STATUSES } from "../constants/status"
export const COGNITO_ERROR_MESSAGES = {
    UsernameExistsException: 'ログイン情報が不正です',
    UserNotFoundException: 'ログイン情報が不正です',
    InvalidParameterException: 'エラーが発生しました(E901)',
    InvalidPasswordException: 'パスワードが間違っています',
    CodeMismatchException: 'エラーが発生しました(E902)',
    NotAuthorizedException: 'エラーが発生しました(E903)',
    PasswordResetRequiredException: 'パスワードを再設定してください',
    TooManyRequestsException: 'しばらくしてからもう一度実行してください。安全性を守るため、特定のアクションの回数を制限しております。',
    LimitExceededException: 'しばらくしてからもう一度実行してください。安全性を守るため、特定のアクションの回数を制限しております。',
    ExpiredCodeException: '認証コードの期限が切れました。もう一度実行してください。',
    DEFAULT: 'エラーが発生しました(E999)'
};

export const VALIDATION_MESSAGES = {
    INVALID_EMAIL_FORMAT: '無効なメールアドレス形式',
    EMAIL_REQUIRED: 'メールアドレスは必須です',
    PASSWORD_MIN_8: 'パスワードは8文字以上にしてください',
    PASSWORD_REQUIRED: 'パスワードが必要です',
    PASSWORDS_MUST_MATCH: 'パスワード確認はパスワードと同じに入力してください',
    PASSWORD_CONFIRMATION_REQUIRED: 'パスワード確認が必要です',
    USERNAME_SPACES: 'スペースは使用できません',
    USERNAME_REQUIRED: 'ユーザー名は必須です',
    INVALID_ROLE: '無効な役割',
    ROLE_REQUIRED: 'ロールは必須です',
    GOVERNMENT_NAME_REQUIRED: '自治体は必須です。',
    PREFECTURE_REQUIRED: '都道府県は必須です。',
    MAX_EXT_MSG_USER_REQUIRED: 'ユーザー毎に権限メッセージの最大数が必要です。',
    MAX_EXT_MSG_GOVERNMENT_REQUIRED: '自治体毎に権限メッセージの最大数が必要です。',
    MAX_INT_MSG_USER_REQUIRED: 'ユーザーごとの内部メッセージの最大数が必要です。',
    MAX_INT_MSG_GOVERNMENT_REQUIRED: '自治体毎の内部メッセージの最大数が必要です。',
    INVALID_IP_FORMAT: '無効なIP形式です。',
    IP_NOT_ACCEPTED: 'このIPは受け入れられません。',
    IP_REQUIRED: 'IPホワイトリスト必須。',
    USER_DOES_NOT_BELONG: 'ユーザーはこのシステムに属していません。',
    USER_NOT_EXIST: 'ユーザーは存在しません。',
    DUPLICATED_ROLE: 'No duplicate roles allowed.',
    VALUE_MUST_NOT_BE_NEGATIVE : "入力値は0より大きくなければなりません。"
};

export const NOTIFICATION_MESSAGES = {
    HAVE_NO_USER: 'ユーザーがいません。',
}

export const BUTTON_LABELS = {
    BUTTON_BACK: '戻る',
    BUTTON_CLOSE: '閉じる',
    BUTTON_CANCEL: 'キャンセル',
    BUTTON_EDIT: '編集',
    BUTTON_CORRECT: '修正',
    BUTTON_DELETE: '削除',
    BUTTON_REGISTER: '登録',
    BUTTON_REQUEST_DELETE: '削除依頼',
    BUTTON_REGISTER_NEW: '新規登録',
    BUTTON_FOR_CONTACT: '連絡用',
    BUTTON_DETAIL: '詳細',
    BUTTON_SEARCH: '検索',
    BUTTON_ACCEPT_PROCESS: 'ロード承認',
    BUTTON_ACCEPT_REPROCESS: '再実行承認',
    BUTTON_ACCEPT_DELETE: '削除を承認する',
    BUTTON_REJECT: '却下',
    BUTTON_REJECT_PROCESS :'ロード却下',
    BUTTON_REJECT_REPROCESS: '再実行却下',
    BUTTON_REQUEST_PROCESS: 'ロード依頼',
    BUTTON_REQUEST_REPROCESS: '再実行依頼',
    BUTTON_REJECT_DELETE :'削除却下',
    BUTTON_DOWNLOAD: 'ダウンロード',
    BUTTON_REUPLOAD: '再アップロード',
    BUTTON_REGISTER_CSV: 'CSV一括登録',
    BUTTON_SET_SCHEDULED: '日時',
}

export const RESOURCE_STATUS_LABELS = {
    [RESOURCE_STATUS.SCRAPING]: 'スクレイピング中',
    [RESOURCE_STATUS.SCRAPE_FINISHED]: 'スクレイピング完了',
    [RESOURCE_STATUS.SCRAPE_FAILED]: 'スクレイピングエラー',
    [RESOURCE_STATUS.S3_FINISHED]: 'データ保存完了',
    [RESOURCE_STATUS.S3_FAILED]: 'データ保存エラー',

    [RESOURCE_STATUS.PENDING]: '編集中',
    [RESOURCE_STATUS.PROCESS_REQUESTING]: 'ロード依頼',
    [RESOURCE_STATUS.REPROCESS_REQUESTING]: '再実行依頼',
    [RESOURCE_STATUS.PROCESSING]: 'ロード中',
    [RESOURCE_STATUS.PROCESS_REJECTED]: 'ロード却下',
    [RESOURCE_STATUS.FAILED]: 'エラー',
    [RESOURCE_STATUS.FINISHED]: 'ロード済み',

    [RESOURCE_STATUS.DELETE_REQUESTING]: '削除依頼',
    [RESOURCE_STATUS.DELETE_REJECTED]: '削除却下',
    [RESOURCE_STATUS.DELETING]: '削除中',
    [RESOURCE_STATUS.DELETED]:'削除済み'
}

export const RESOURCE_TYPE_LABELS = {
    'WEBSITE': 'WEBサイト',
    'FILE': 'ファイル',
    'FAQ': 'FAQ'
}

export const WEBPAGE_STATUSES_LABELS = {
    [WEBPAGE_STATUSES.PENDING]: '編集中',
    [WEBPAGE_STATUSES.PROCESS_REQUESTING]: 'ロード依頼',
    [WEBPAGE_STATUSES.PROCESS_REJECTED]: 'ロード却下',
    [WEBPAGE_STATUSES.SCRAPING]: 'スクレイピング中',
    [WEBPAGE_STATUSES.SCRAPE_FINISHED]: 'スクレイピング完了',
    [WEBPAGE_STATUSES.SCRAPE_FAILED]: 'スクレイピングエラー',
    [WEBPAGE_STATUSES.S3_FINISHED]: 'データ保存完了',
    [WEBPAGE_STATUSES.S3_FAILED]: 'データ保存エラー',
    [WEBPAGE_STATUSES.FINISHED]: 'ロード済み',
    [WEBPAGE_STATUSES.FAILED]: 'エラー',
    [WEBPAGE_STATUSES.REPROCESS_REQUESTING]: '再実行依頼',
    [WEBPAGE_STATUSES.DELETE_REQUESTING]: '削除依頼',
    [WEBPAGE_STATUSES.DELETING]: '削除中',
}

export const MESSAGES = {
    SOMETHING_WRONG: 'エラーが発生しました、やり直してください。',
    DELETED_FAQ_SUCCESSFULLY: 'FAQを正常に削除しました。',
    DELETED_FILE_SUCCESSFULLY: 'ファイルを正常に削除しました。',
    DELETED_WEB_SUCCESSFULLY: 'ウェブサイトを正常に削除しました。',
    FILE_DELETE_REQUESTED_SUCCESSFULLY: 'ファイル削除を依頼しました。',
    REQUESTED_REPROCESSING_FILE_SUCCESSFULLY: 'ファイルを正常に再実行依頼しました。',
    SAVED_TAG_SETTING_SUCCESSFULLY: 'チャットタグ設定を正常に保存しました。',
    URL_ALREADY_REGISTERED: 'URLが既に登録されました。',
    DELETED_DATA_SUCCESSFULLY: 'データを正常に削除しました。',
    EXECUTION_UPDATED_SUCCESSFULLY: '実行情報を正常に変更しました。',
    LONGTIME_PROCESS: '処理時間がかかりますので、お待ちください。'
}

export const WEEKDAY_LABELS = {
    [WEEKDAYS.MON]: '月',
    [WEEKDAYS.TUE]: '火',
    [WEEKDAYS.WED]: '水',
    [WEEKDAYS.THU]: '木',
    [WEEKDAYS.FRI]: '金',
    [WEEKDAYS.SAT]: '土',
    [WEEKDAYS.SUN]: '日',
}