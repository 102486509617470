export const GOVERNMENT_STATUS = {
    active: 1,
    disabled: 0
}
export const RESOURCE_STATUS = {
    SCRAPING: 'SCRAPING',
    SCRAPE_FINISHED: 'SCRAPE_FINISHED',
    SCRAPE_FAILED: 'SCRAPE_FAILED',
    S3_FINISHED: 'S3_FINISHED',
    S3_FAILED: 'S3_FAILED',
    
    PENDING: 'PENDING',
    WAITING: 'WAITING',
    REPROCESS_REQUESTING: 'REPROCESS_REQUESTING',
    PROCESS_REQUESTING: 'PROCESS_REQUESTING',
    PROCESSING: 'PROCESSING',
    PROCESS_REJECTED: 'PROCESS_REJECTED',
    FAILED: 'FAILED',
    FINISHED: 'FINISHED',

    DELETE_REQUESTING: 'DELETE_REQUESTING',
    DELETE_REJECTED: 'DELETE_REJECTED',
    DELETING: 'DELETING',
    DELETED:'DELETED'
}

export const WEBPAGE_STATUSES = {
    PENDING: 'PENDING',
    WAITING: 'WAITING',
    PROCESS_REQUESTING: 'PROCESS_REQUESTING',
    PROCESS_REJECTED: 'PROCESS_REJECTED',
    SCRAPING: 'SCRAPING',
    SCRAPE_FINISHED: 'SCRAPE_FINISHED',
    SCRAPE_FAILED: 'SCRAPE_FAILED',
    S3_FINISHED: 'S3_FINISHED',
    S3_FAILED: 'S3_FAILED',
    FINISHED: 'FINISHED',
    FAILED: 'FAILED',
    REPROCESS_REQUESTING: 'REPROCESS_REQUESTING',
    DELETE_REQUESTING: 'DELETE_REQUESTING',
    DELETING: 'DELETING',
}

export const PROCESS_TYPES = {
    CREATE: 'CREATE',
    DELETE: 'DELETE'
}

export const EXECUTION_TYPES = {
    IMMEDIATE: 'IMMEDIATE',
    SCHEDULED: 'SCHEDULED',
    RECURRING: 'RECURRING'
}

export const RESOURCE_TYPES = {
    FAQ: 'FAQ',
    FILE: 'FILE',
    WEBSITE: 'WEBSITE'
}