import {createSlice} from '@reduxjs/toolkit';
import {fetchUsers, deleteUser, userLogin, registerUser, logoutUser} from './userActions';

const initialState = {
    users: [],
    loading: false,
    error: null,
    userToken: localStorage.getItem('userToken') || null,
    success: false,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setCredentials: (state, {payload}) => {
            state.userInfo = payload
        },
    },
    extraReducers: {
        // login user
        [userLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userLogin.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.userInfo = payload
            state.userToken = payload.userToken
            state.success = true
            state.error = null
            localStorage.setItem('userInfo', JSON.stringify(payload));
        },
        [userLogin.rejected]: (state, action) => {
            state.loading = false
            state.error = action?.payload ?? 'Something wrong when login.'
        },
        [fetchUsers.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        [fetchUsers.fulfilled]: (state, action) => {
            state.users = action.payload;
            state.loading = false;
            state.success = true;
            state.error = null;
        },
        [fetchUsers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            state.success = false;
        },
        [deleteUser.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = null;
        },
        [deleteUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            state.success = false;
        },
        // register user
        [registerUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [registerUser.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.success = true
        },
        [registerUser.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },
        [logoutUser.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.userInfo = null
            state.userToken = null
            state.error = null
            localStorage.removeItem('userInfo');
        },
    },
});

export const {setCredentials} = userSlice.actions

export default userSlice.reducer;
