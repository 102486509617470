import { API } from 'aws-amplify';
import { 
  listInternalConversations, 
  listExternalConversations,
  listAnalysis,listInternalMessages,
  listExternalMessages
 } from '../graphql/customQueries';
import { 
  getMonthlyAnalysis as getMonthlyAnalysisCmd 
} from '../graphql/queries';
import { APP_AUTH_MODE } from '../constants/app';
import { listAnalyses } from 'graphql/queries';
function convertJSTRangeToUTCISO(japanMonthYear) {
    const japanStartTimeStr = `${japanMonthYear}-01T00:00:00.000Z`;
    const japanStartTime = new Date(japanStartTimeStr);
    const nextMonth = new Date(japanStartTime);
    nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 1);
  
    // Chuyển đổi thời gian thành thời gian UTC và định dạng lại thành chuỗi ISO
    const utcStartTimeISO = japanStartTime.toISOString();
    const utcEndTimeISO = nextMonth.toISOString();
  
    return { startTime: utcStartTimeISO, endTime: utcEndTimeISO };
  }
  
  const listUserPerMonth = async (monthYear, apptype) => {
    const { startTime, endTime } = convertJSTRangeToUTCISO(monthYear);
  
    try {
      let externalMessages = [];
      let internalMessages = [];
      let nextTokenExternal = null;
      let nextTokenInternal = null;
  
      if (apptype === 'ADMIN') {
        do {
          const externalRes = await API.graphql({
            query: listExternalMessages,
            variables: {
              filter: { createdAt: { between: [startTime, endTime] }, role: { eq: 'USER' } },
              nextToken: nextTokenExternal,
            },
          });
  
          const internalRes = await API.graphql({
            query: listInternalMessages,
            variables: {
              filter: { createdAt: { between: [startTime, endTime] }, role: { eq: 'USER' } },
              nextToken: nextTokenInternal,
            },
          });
  
          if (externalRes.errors || internalRes.errors) {
            return {
              success: false,
              error: externalRes.errors || internalRes.errors,
            };
          }
  
          externalMessages = externalMessages.concat(externalRes.data.listExternalMessages.items);
          internalMessages = internalMessages.concat(internalRes.data.listInternalMessages.items);
  
          nextTokenExternal = externalRes.data.listExternalMessages.nextToken;
          nextTokenInternal = internalRes.data.listInternalMessages.nextToken;
        } while (nextTokenExternal || nextTokenInternal);
      } else {
        const query = apptype === 'INSIDE_ADMIN' ? listInternalMessages : listExternalMessages;
        const property = apptype === 'INSIDE_ADMIN' ? 'listInternalMessages' : 'listExternalMessages';
        let nextToken = null;
  
        do {
          const res = await API.graphql({
            query,
            variables: {
              filter: { createdAt: { between: [startTime, endTime] }, role: { eq: 'USER' } },
              nextToken,
            },
          });
  
          if (res.errors) {
            return {
              success: false,
              error: res.errors,
            };
          }
  
          externalMessages = externalMessages.concat(res.data[property].items);
          nextToken = res.data[property].nextToken;
        } while (nextToken);
      }
  
      const allIds = externalMessages.concat(internalMessages).map((item) => item.conversationId);
      const uniqueIdsSet = new Set(allIds);
      const uniqueIdsCount = uniqueIdsSet.size;
      console.log('ids list',uniqueIdsSet)
  
      console.log('hoang47', uniqueIdsCount);
  
      return {
        success: true,
        itemRes: externalMessages.concat(internalMessages),
        userIdcount: uniqueIdsCount,
      };
    } catch (error) {
      console.error('Error calling GraphQL API:', error);
      throw error;
    }
  };
  
const fetchAnalysisData = async (monthYear, cityId, apptype) => {
    const { startTime, endTime } = convertJSTRangeToUTCISO(monthYear);
  
    try {
      if (!cityId) {
        throw new Error('cityId is required and cannot be null');
      }
  
      const analysisData = await API.graphql({
        query: listAnalysis,
        variables: {
          governmentId: cityId,
          createdAt: { between: [startTime, endTime] },
        },
        authMode: APP_AUTH_MODE,
      });
      const res = analysisData.data.listAnalyses.items;
      let Arraydata = undefined;

        if (apptype === 'OUTSIDE_ADMIN') {
         Arraydata = res.map(item => {
          
            return {
            currentDay: item.currentDay,
            conversations: item.external.conversations,
            messages: item.external.messages,
            totalRegisteredUsers:item.external.totalRegisteredUsers
            };
        });
        } else if (apptype === 'INSIDE_ADMIN') {
         Arraydata = res.map(item => {
            return {
            currentDay: item.currentDay,
            conversations: item.internal.conversations,
            messages: item.internal.messages,
            totalRegisteredUsers:item.internal.totalRegisteredUsers
            };
        });
        }else if(apptype === 'ADMIN'){
          Arraydata = res.map(item => {
            return {
            currentDay: item.currentDay,
            conversations: item.internal.conversations,
            messages: item.internal.messages,
            totalRegisteredUsers:item.internal.totalRegisteredUsers
            };
        });
        }
        let totalMessages = 0;
        let totalDislikedMessages = 0;
        let totalLikedMessages = 0;
        let totalConversations = 0;
        let totalGoodConversations = 0;
        let totalBadConversations = 0;

        for (const item of Arraydata) {
            if (item.messages && item.conversations) {
                totalMessages += item.messages.total;
                totalDislikedMessages += item.messages.disliked;
                totalLikedMessages += item.messages.liked;
                totalConversations += item.conversations.total;
                totalGoodConversations += item.conversations.good;
                totalBadConversations += item.conversations.bad;
            }
        }
      return {
        success: true,
        Arraydata,
        totalMessages:totalMessages,
        totalDislikedMessages:totalDislikedMessages,
        totalLikedMessages:totalLikedMessages,
        totalConversations:totalConversations,
        totalGoodConversations:totalGoodConversations,
        totalBadConversations:totalBadConversations

      };
    } catch (error) {
      // Handle errors
      console.error('Error calling GraphQL API:', error);
      throw error;
    }
  };

const getMonthlyAnalysis = async (governmentId, japanMonth) => {
  try {
    const res = await API.graphql({
      query: getMonthlyAnalysisCmd,
      variables: {
        governmentId: governmentId,
        japanMonth: japanMonth
      },
      authMode: APP_AUTH_MODE
    });
    return {
      success: true,
      data: res.data.getMonthlyAnalysis
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const listAnalysesInMonth = async (governmentId, japanMonth) => {
  console.log(japanMonth)
  console.log(governmentId)
  try {
    const variables = {
      governmentId,
      sortDirection: 'ASC',
      japanDay: {
        beginsWith: japanMonth
      }
    };
    
    let nextToken = null;
    const items = [];
    while (true) {
      // variables.nextToken = nextToken;
      const res = await API.graphql({
        query: listAnalyses,
        variables,
        authMode: APP_AUTH_MODE
      });
      nextToken = res.data.listAnalyses.nextToken;
      items.push(...res.data.listAnalyses.items);
      if (!nextToken) break;
    }
    for (const item of items) {
      item.externalUserCount = item.externalUserCount || 0;
      item.internalUserCount = item.internalUserCount || 0;
      item.allUserCount = item.externalUserCount + item.internalUserCount;
      item.externalMessage = item.externalMessage || 0;
      item.internalMessage = item.internalMessage || 0;
      item.allMessage = item.externalMessage + item.internalMessage;
      
    }
    return {
      success: true,
      data: items
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
export { 
  listUserPerMonth,
  fetchAnalysisData,
  getMonthlyAnalysis,
  listAnalysesInMonth
};
