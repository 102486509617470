import React, {useEffect, useState, useCallback} from 'react';
import {useParams, useHistory, useRouteMatch} from 'react-router-dom';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUser} from '../../graphql/queries';
import {updateUser} from '../../graphql/mutations';
import paths from '../../constants/paths';
import AccountForm from "../../components/AccountForm";
import {APP_AUTH_MODE} from "../../constants/app";
import useRoutes from "../../routes";
import SpinnerPage from "../../components/SpinnerPage";
import {Card, Col} from "react-bootstrap";
import {editAccountSchema, registerAccountSchema} from "../../utils/validationSchemas";
import {editUser, registerUser} from "../../features/user/userActions";
import {useDispatch, useSelector} from "react-redux";

const EditAccount = () => {
    const {accountId} = useParams();
    const [account, setAccount] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const dispatch = useDispatch();
    const {error} = useSelector((state) => state.users)

    const fetchAccountDetails = useCallback(async () => {
        setLoading(true);
        try {
            const result = await API.graphql({
                query: getUser,
                variables: {id: accountId},
                authMode: APP_AUTH_MODE,
            });
            const userData = result?.data?.getUser;
            if (userData) {
                const formattedData = {
                    username: userData.username,
                    email: userData.email,
                    role: userData ? userData.groups[0] : null,
                    status: userData.status,
                };
                setAccount(formattedData);
            }
        } catch (error) {
            console.error('Error fetching account details:', error);
        } finally {
            setLoading(false);
        }
    }, [accountId]);

    useEffect(() => {
        fetchAccountDetails();
    }, [fetchAccountDetails]);

    const handleEditAccount = useCallback(
        async (data) => {
            console.log(data)
            const jwtToken = localStorage.getItem('userToken');

            const options = {
                headers: {
                    Authorization: jwtToken,
                },
                body: {
                    userId: accountId,
                    newPassword: data.password ?? null,
                    newRole: data.role,
                    username: data.username
                }
            };
            try {
                const response = await API.post('userRestApi', '/update-user', options);
                const result = await API.graphql({
                    query: getUser,
                    variables: {id: accountId},
                    authMode: APP_AUTH_MODE,
                });
                const userGov = result?.data?.getUser?.governmentId;
                if (response.success) {
                    history.push(`/admin/governments/${userGov}/accounts`)
                }
            } catch (error) {
                console.log(error)
            }

        },
        [accountId, history]
    );

    return <Col md="12">
        <Card className="strpied-tabled-with-hover">
            <Card.Header>
                <Card.Title as="h4">アカウント編集</Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-4">
                {loading ? <SpinnerPage/> : <AccountForm
                    onSubmit={handleEditAccount}
                    defaultValues={account}
                    loading={loading}
                    isEdit={true}
                    resolver={editAccountSchema}
                    error={null} // Replace with proper error handling
                />}
            </Card.Body>
        </Card>
    </Col>
};

export default EditAccount;
