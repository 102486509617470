import React from "react";
import PropTypes from 'prop-types';
import { Button, Modal, Container, Form } from 'react-bootstrap';
import { Label, Input,  } from "reactstrap";
import "./WebRegisterDialog.css";
import { BUTTON_LABELS } from "../../lang/ja";

const propTypes = {
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  url: PropTypes.string,
  onClose: PropTypes.func,
  onRegister: PropTypes.func,
  editable: PropTypes.bool
}
const defaultProps = {
  isOpen: false,
  isNew: true,
  url: '',
  onClose: () => {},
  onRegister: (url) => {},
  editable: true
}

const WebRegisterDialog  = (props) => {
  /***** States and Variables *****/
  const { isOpen, onClose, onRegister, editable } = props;
  const [url, setUrl] = React.useState('');
  console.log("props.url",props.url)
  /***** Processing *****/ 
  React.useEffect(()=>{
    setUrl(props.url);
  }, [props.url])
  /***** Main Render *****/
  if (!isOpen) return null;
  return <div
  className="modal show"
  style={{ display: 'block', position: 'absolute' }}
>
  <Modal.Dialog className="p-0">
    <Modal.Header closeButton onHide={onClose}>
      <Modal.Title className="">
        {editable? (!props.isNew? BUTTON_LABELS.BUTTON_EDIT: BUTTON_LABELS.BUTTON_REGISTER_NEW): '詳細'}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body
    style={{
      maxHeight: '256px',
      overflow: 'auto'
    }}>
      <Container 
      style={{
        paddingBottom: '8px'
      }}
      className="d-flex align-items-center">
        <Label className="w-25 m-0">
          URL
        </Label>
        <Input 
        className="w-75"
        value={url}
        disabled={!editable}
        onChange={e=>{setUrl(e.target.value)}}/>
      </Container>
    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-end">
      <Button 
      className="btn-outline btn-wd mr-1"
      variant="default"
      onClick={onClose}>
        {BUTTON_LABELS.BUTTON_CANCEL}
      </Button>
      {editable && <Button 
      className="btn-outline btn-wd mr-1"
      variant="default"
      onClick={()=>{onRegister(url)}}>
        {BUTTON_LABELS.BUTTON_REGISTER}
      </Button>}
    </Modal.Footer>
  </Modal.Dialog>
</div>
}

WebRegisterDialog.propTypes = propTypes;
WebRegisterDialog.defaultProps = defaultProps;

export default WebRegisterDialog;