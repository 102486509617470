import React from "react";
import moment from "moment";
// react-bootstrap components
import {
    Button,
    Card,
    Table,
    Col,
} from "react-bootstrap";
import FileRegisterDialog from "../../views/Dialog/FileRegisterDialog";
import BatchModalDialog from "../../components/BatchModalDialog";
import {
    createFile,
    listFiles,
    requestProcessingResource,
    updateResource,
    requestDeletingResource,
    requestReprocessingResource,
    deleteResource
} from "../../apis/ResourceApi";
import "./CommonTable.css";
import {useSelector} from 'react-redux';
import {APP_TYPE, APP_TYPES} from "../../constants/app";
import {BUTTON_LABELS, MESSAGES} from "../../lang/ja";
import PaginationComponent from "../../components/PaginationComponent";
import {NUM_ITEMS_PER_PAGE} from "../../constants/table";
import {PROCESS_TYPES, RESOURCE_STATUS} from "../../constants/status";
import FanLoadingIcon from "../../components/FanLoadingIcon";
import { useDialog } from "contexts/DialogContext";
import { v4 as uuidv4 } from 'uuid';
import { Storage } from "aws-amplify";
import { getStatusLabel } from "utils/resources";
import { getProcessTime } from "utils/resources";
function FilePage() {
    /***** States And Variables *****/
    const [registerDialogOpen, setRegisterDialogOpen] = React.useState(false);
    const [replacedFile, setReplacedFile] = React.useState(null);
    const [selectedFileForBatch, setSelectedFileForBatch] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const {userInfo} = useSelector((state) => state.users)
    const governmentId = userInfo?.governmentId;
    const [curPage, setCurPage] = React.useState(0);
    const numPages = Math.ceil(files.length/NUM_ITEMS_PER_PAGE);
    const nextTokenRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const { success, error } = useDialog();

  const handleSetScheduled = (file) => {
    setSelectedFileForBatch(file);
  };
  
    /***** Processing *****/
    const downloadFiles = async () => {
        const res =  await listFiles(governmentId, APP_TYPE===APP_TYPES.OUTSIDE_ADMIN, null);
        if (res.success) {
            setFiles(res.data);
            nextTokenRef.current = res.nextToken;
        }
    }
    React.useEffect(() => {
        if (governmentId) downloadFiles();
    }, [governmentId])

    const downloadBlob = (blob, filename) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }
    /***** Event Handlers *****/
    const onRegister = async (uploadFiles) => {
        let newFiles = files;
        if (replacedFile) {
            const deleteRes = await deleteResource(replacedFile);
            if (deleteRes.success) {
                files.splice(files.indexOf(replacedFile), 1);
                newFiles = Object.assign([], files);
                setFiles(newFiles);
            }
            else {
                error(MESSAGES.SOMETHING_WRONG);
            }
        }
        const isExternal = APP_TYPE !== APP_TYPES.INSIDE_ADMIN;
        for (const file of uploadFiles) {
            const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
            const id = uuidv4();
            await Storage.put(`${governmentId}/${isExternal? 'external': 'internal'}/file/${id}.${ext}`, file);
            const res  = await createFile(id, governmentId, file.name, isExternal);
            if (res.success) {
                newFiles = [res.data, ...newFiles]
                setFiles(newFiles);
            }
        }
        setRegisterDialogOpen(false);
        setReplacedFile(null);
    }
    const handleChangePage = async (page) => {
        setCurPage(page);
        if (page+1===numPages) {
            const nextToken = nextTokenRef.current;
            if (nextToken) {
                const res = await listFiles(governmentId, APP_TYPE===APP_TYPES.OUTSIDE_ADMIN, nextToken);
                if (res.success) {
                    if (nextToken) {
                        files.push(...res.data);
                        setFiles(Object.assign([], files));
                    }
                    else {
                        setFiles(res.data);
                    }
                    nextTokenRef.current = res.nextToken;
                }
            }
        }
    };
    const handleRequestDeleting = async (file) => {
        if (loading) return;
        setLoading(true);
        const res = await requestDeletingResource(file);
        console.log('VANVIET', res)
        if (res.success) {
            files.splice(files.indexOf(file), 1, res.data);
            setFiles(Object.assign([], files));
            success(MESSAGES.FILE_DELETE_REQUESTED_SUCCESSFULLY);
        }
        else {
            error(MESSAGES.SOMETHING_WRONG);
        }
        setLoading(false);
    }
    const handleRequestReprocessing = async (file) => {
        if (loading) return;
        setLoading(true);
        const res = await requestReprocessingResource(file);
        if (res.success) {
            files.splice(files.indexOf(file), 1, res.data);
            setFiles(Object.assign([], files));
            success(MESSAGES.REQUESTED_REPROCESSING_FILE_SUCCESSFULLY);
        }
        else {
            error(MESSAGES.SOMETHING_WRONG);
        }
        setLoading(false);
    }
    
    const onDeleteFile = async (file) => {
        if (loading) return;
        setLoading(true);
        const res = await deleteResource(file);
        console.log("VANVIET", res)
        if (res.success) {
            files.splice(files.indexOf(file), 1);
            setFiles(Object.assign([], files));
            success(MESSAGES.DELETED_FILE_SUCCESSFULLY);
        }
        else {
            error(MESSAGES.SOMETHING_WRONG);
        }
        setLoading(false);
    }
    const onDownload = async (file) => {
        const isExternal = APP_TYPE !== APP_TYPES.INSIDE_ADMIN;
        const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
        const res = await Storage.get(`${governmentId}/${isExternal? 'external': 'internal'}/file/${file.id}.${ext}`, {download: true})
        downloadBlob(res.Body, file.name);
    }
    const handleRequestProcessing = async (file) => {
        if (loading) return;
        setLoading(true);
        const res = await requestProcessingResource(file);
        if (res.success) {
            const pos = files.indexOf(file);
            if (pos>=0) {
                files.splice(pos, 1, res.data);
                setFiles(Object.assign([], files));
            }
        }
        setLoading(false);
    }

    const onUpdateResourceExecution = async (executionType, executionDetail) => {
        if (loading) return;
        setLoading(true);
        const file = selectedFileForBatch;
        const res = await updateResource(file, {execution: executionType, executionDetail});
        console.log('RES', res)
        if (res.success) {
            success(MESSAGES.EXECUTION_UPDATED_SUCCESSFULLY);
            files.splice(files.indexOf(file), 1, res.data);
            setFiles(Object.assign([], files));
        }
        else {
            error(MESSAGES.SOMETHING_WRONG);
        }
        setSelectedFileForBatch(null);
        setLoading(false);
    }
    /***** Sub Components *****/
    const renderActions = (file) => {
        return <>
            {/***** DOWNLOAD *****/}
            <Button 
            className="btn-outline mr-1" variant="default"
            onClick={()=>{onDownload(file)}}>
                {BUTTON_LABELS.BUTTON_DOWNLOAD}
            </Button>
            {/***** REUPLOAD *****/}
            <Button 
            className="btn-outline mr-1" variant="default"
            onClick={()=>{setReplacedFile(file)}}>
                {BUTTON_LABELS.BUTTON_REUPLOAD}
            </Button>
            {/***** REQUEST PROCESS *****/}
            {file.status===RESOURCE_STATUS.PENDING && <Button 
            className="btn-outline mr-1" variant="default" 
            onClick={() => handleRequestProcessing(file)}>
                {BUTTON_LABELS.BUTTON_REQUEST_PROCESS}
            </Button>}
            {/***** REQUEST DELETE *****/}
            {file.status===RESOURCE_STATUS.FINISHED && <Button 
            className="btn-outline mr-1" variant="default" 
            onClick={() => handleRequestDeleting(file)}>
                {BUTTON_LABELS.BUTTON_REQUEST_DELETE}
            </Button>}
            {/***** DELETE *****/}
            {(file.status===RESOURCE_STATUS.PENDING || file.status===RESOURCE_STATUS.PROCESS_REQUESTING) && <Button 
            className="btn-outline mr-1" variant="default" 
            onClick={() => onDeleteFile(file)}>
                {BUTTON_LABELS.BUTTON_DELETE}
            </Button>}
            {/***** REPROCESS REQUEST *****/}
            {file.status===RESOURCE_STATUS.FAILED && <Button 
            className="btn-outline mr-1" variant="default" 
            onClick={() => handleRequestReprocessing(file)}>
                {BUTTON_LABELS.BUTTON_REQUEST_REPROCESS}
            </Button>}
            {/***** SET SCHEDULED *****/}
            <Button
            className="btn-outline mr-1"
            variant="default"
            onClick={(event)=>{handleSetScheduled(file)}}>
            {BUTTON_LABELS.BUTTON_SET_SCHEDULED}
            </Button>
        </>
    }

    /***** Main Render *****/
    return <>
        <Col md="12">
            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Card.Title as="h4">データ管理 &gt; ファイル</Card.Title>
                    <div className="d-flex flex-row justify-content-between align-items-end">
                        <PaginationComponent
                            currentPage={curPage}
                            totalItems={files.length}
                            onPageChange={handleChangePage}
                        />
                        <Button
                            className="btn-outline btn-wd mr-1"
                            variant="default"
                            onClick={() => {
                                setRegisterDialogOpen(true)
                            }}>
                            {BUTTON_LABELS.BUTTON_REGISTER_NEW}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                    <Table className="table-hover table-striped w-full">
                        <thead>
                        <tr>
                            <th>ファイル名</th>
                            <th>更新日時</th>
                            <th>反映予定日時</th>
                            <th>ステータス</th>
                            <th>アクション</th>
                        </tr>
                        </thead>
                        <tbody>
                        {files.map((file, i) => {
                            if (i < curPage * NUM_ITEMS_PER_PAGE || i >= (curPage + 1) * NUM_ITEMS_PER_PAGE) return null;
                            return <tr>
                                <td className="common_table_name_cell">
                                    {file.name}
                                </td>
                                <td>{moment(file.updatedAt).format("yyyy/MM/DD HH:mm")}</td>
                                <td>{getProcessTime(file)}</td>
                                <td>{getStatusLabel(file)}</td>
                                <td>
                                    {renderActions(file)}
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
        <BatchModalDialog 
            isOpen={!!selectedFileForBatch}
            isCreateDialog={selectedFileForBatch?.status===RESOURCE_STATUS.FINISHED}
            onClose={()=>{setSelectedFileForBatch(null)}}
            enableRecurring={false}
            onSubmit={onUpdateResourceExecution}
            initExecutionType={selectedFileForBatch?.execution}
            initExecutionDetail={selectedFileForBatch?.executionDetail}
            editable={selectedFileForBatch?.status!==RESOURCE_STATUS.PROCESSING}
            />
        <FileRegisterDialog
            isOpen={registerDialogOpen || replacedFile}
            onClose={() => {
                setRegisterDialogOpen(false);
                setReplacedFile(null);
            }}
            onRegister={onRegister}/>
        {loading && <FanLoadingIcon size={80}/>}
    </>
}

export default FilePage;