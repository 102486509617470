import React, {useEffect, useState} from 'react';
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Line,
    ResponsiveContainer,
    ComposedChart,
    PieChart,
    Pie,
    Cell
} from 'recharts';
import { Select, MenuItem, FormControl, InputLabel, Grid, Paper } from "@mui/material";
import styled from '@emotion/styled'
import SpinnerPage from "../../components/SpinnerPage";
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import {useSelector} from "react-redux";
import { getMonthlyAnalysis, listAnalysesInMonth } from '../../apis/DashboardApi'; 

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const startMonth = 10;
const startYear = 2023;
const monthList = [];
for (let year = startYear; year <= currentYear; year++) {
    const start = (year === startYear) ? startMonth : 1;
    const end = (year === currentYear) ? currentMonth : 12;
    for (let month = start; month <= end; month++) {
        monthList.push({
            label: `${year}年${String(month).padStart(2, '0')}月`,
            value: `${year}-${String(month).padStart(2, '0')}`
        });
    }
}

const months = monthList.reverse();


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const TotalWrapper = styled(Paper)`
  padding: 20px;
`

const DashboardPage = () => {
    const [selectedMonth, setSelectedMonth] = useState(`${currentYear}-${String(currentMonth).padStart(2, '0')}`);
    // const [analysisData, setAnalysisData] = useState([]);
    const [conversationData, setConversationData] = useState(null);
    const [chatData, setChatData] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const { userInfo } = useSelector((state) => state.users)
    const [monthlyUserCnt, setMonthlyUserCnt] = useState(0);
    const [dayAnalyses, setDayAnalyses] = useState([]);
    const [monthlyAnalysis, setMonthlyAnalysis] = useState(null); // TODO
    const governmentId = userInfo?.governmentId;
    const key = APP_TYPE===APP_TYPES.OUTSIDE_ADMIN && "external" || 
    APP_TYPE===APP_TYPES.INSIDE_ADMIN && "internal" || 
    "all";

    const handleMonthChange = async (event) => {
        setSelectedMonth(event.target.value);
    };

    const fetchAnalysisData = async (monthYear, governmentId) => {
        try {
            setIsLoading(true);
            setConversationData(null);
            setChatData(null);
            const res = await listAnalysesInMonth(governmentId, monthYear);
            if (res.success) {
                setDayAnalyses(res.data);
            }
            // const options = {
            //     queryStringParameters: {
            //         monthYear,
            //         governmentId
            //     },
            // };

            // const endpoint =  APP_TYPE === APP_TYPES.INSIDE_ADMIN ? '/get-internal-analyzing' : (APP_TYPE === APP_TYPES.ALL || APP_TYPE === APP_TYPES.ADMIN ? '/get-total-analyzing' : '/get-external-analyzing')

            // const response = await API.get('analyzingRestApi', endpoint, options);

            // if (response) {
            //     setAnalysisData(response);
            // }
        } catch (error) {
            console.error('Error fetching analysis data', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMonthlyUserCnt = async (selectedMonth, governmentId) => {
        try {
            setIsLoading(true);
            setConversationData(null);
            setChatData(null);
            const res = await getMonthlyAnalysis(governmentId, selectedMonth);
            if (res.data) {
                setMonthlyAnalysis(res.data);
                const data = res.data;
                console.log(data)
                if (data && APP_TYPE != APP_TYPES.ADMIN) {
                    
                    setMonthlyUserCnt(data[`${key}UserCount`]);
                    if (data[`${key}ConversationCount`] > 0) {
                        setConversationData([
                            {name: '良い', value: data[`${key}GoodEvalCount`] ?? 0},
                            {name: '悪い', value: data[`${key}BadEvalCount`] ?? 0},
                            {
                                name: '評価なし',
                                value: data[`${key}ConversationCount`]  - (data[`${key}GoodEvalCount`] + data[`${key}BadEvalCount`])
                            }
                        ])
                    }
                    
                    if ( data[`${key}LikeCount`] > 0 || data[`${key}DislikeCount`] > 0) {
                        setChatData([{name: '良い', value: data[`${key}LikeCount`] ?? 0},
                            {name: '悪い', value: data[`${key}DislikeCount`] ?? 0}
                        ])
                    }
                }
                else {
                    setMonthlyUserCnt(data?.externalUserCount + data?.internalUserCount);
                    if (data?.internalConversationCount + data?.externalConversationCount > 0) {
                        setConversationData([
                            {name: '良い', value: data?.externalGoodEvalCount+data?.internalGoodEvalCount ?? 0},
                            {name: '悪い', value: data?.externalBadEvalCount+data?.internalBadEvalCount ?? 0},
                            {
                                name: '評価なし',
                                value: (data?.internalConversationCount + data?.externalConversationCount)  - (data?.intenalGoodEvalCount+data?.extenalGoodEvalCount+data?.intenalBadEvalCount+data?.extenalBadEvalCount),
                            }
                        ])
                    }
                    
                    if ( data?.externalLikeCount+data?.internalLikeCount > 0 || data?.externalDislikeCount+data?.internalDislikeCount > 0) {
                        setChatData([{name: '良い', value: data?.externalLikeCount+data?.internalLikeCount ?? 0},
                            {name: '悪い', value: data?.externalDislikeCount+data?.internalDislikeCount ?? 0}
                        ])
                    }
                }
            }
            else {
                setMonthlyUserCnt(0);
            }
        }
        catch (e) {}
    }

    useEffect(() => {
        fetchAnalysisData(selectedMonth, governmentId);
        fetchMonthlyUserCnt(selectedMonth, governmentId);
    }, [selectedMonth, governmentId]);
    return <>
        {isLoading ? <SpinnerPage/> :
            <div style={{padding: '20px'}}><Grid container spacing={2} marginBottom={5}>
                <Grid item xs={4}>
                    <TotalWrapper>
                        <h3>使用人数</h3>
                        <h3>{monthlyUserCnt}</h3>
                    </TotalWrapper>

                </Grid>
                <Grid item xs={4}>
                    <TotalWrapper>
                        <h3>使用回数</h3>
                        <h3>
                            {dayAnalyses.reduce((s, x)=> {
                                if (APP_TYPE===APP_TYPES.OUTSIDE_ADMIN) {
                                    return s + (x.externalMessage || 0);
                                }
                                if (APP_TYPE===APP_TYPES.INSIDE_ADMIN) {
                                    return s + (x.internalMessage || 0);
                                }
                                return s + (x.externalMessage || 0) + (x.internalMessage || 0);
                            }, 0)}
                        </h3>
                    </TotalWrapper>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                    <FormControl variant="outlined" style={{marginTop: '20px', width: '200px', textAlign: 'left'}}>
                        <InputLabel id="month-select-label">月を選択</InputLabel>
                        <Select
                            labelId="month-select-label"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            label="月を選択"
                        >
                            {months.map((month, index) => (
                                <MenuItem key={index} value={month.value}>
                                    {month.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper style={{padding: 10}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}} className="mb-4">
                                <h3>日ごとの使用人数/回数</h3>
                            </div>
                            {dayAnalyses.length ?
                                <ResponsiveContainer width="100%" height={400}>
                                    <ComposedChart
                                        data={dayAnalyses}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="japanDay"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Bar dataKey={`${key}UserCount`} name="使用人数" barSize={20} fill="#8884d8"/>
                                        <Line 
                                        type="monotone" dataKey={`${key}Message`} name="使用回数"
                                              stroke="#ff7300"/>
                                    </ComposedChart>
                                </ResponsiveContainer> : 'データがありません'}
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container mt={2} spacing={5}>
                    <Grid item xs={6}>
                        <Paper style={{padding: 10}}>
                            <h3>回答への評価</h3>
                            {chatData ?
                                <ResponsiveContainer width="100%" height={400}>
                                    <PieChart>
                                        <Pie
                                            dataKey="value"
                                            data={chatData}
                                            cx="50%"
                                            cy="50%"

                                            fill="#82ca9d"
                                        >
                                            {chatData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                            ))}

                                        </Pie>
                                        <Tooltip/>
                                    </PieChart>
                                </ResponsiveContainer> : 'データがありません'}
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper style={{padding: 10}}>
                            <h3>Chatの評価</h3>
                            {conversationData ?
                                <ResponsiveContainer width="100%" height={400}>
                                    <PieChart>
                                        <Pie
                                            dataKey="value"
                                            data={conversationData}
                                            cx="50%"
                                            cy="50%"
                                            fill="#82ca9d"
                                        >
                                            {conversationData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                            ))}
                                        </Pie>
                                        <Tooltip/>
                                    </PieChart>
                                </ResponsiveContainer> : 'データがありません'}
                        </Paper>
                    </Grid>
                </Grid>

            </div>}
    </>
};

export default DashboardPage;