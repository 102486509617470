import React from 'react';
import {Spinner} from "reactstrap";

const SpinnerPage = () => {
    return <div style={spinnerStyle}><Spinner/></div>
}

const spinnerStyle = {
    width: '100%',
    height: '100vh',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
}

export default SpinnerPage;
