import { WEEKDAYS } from "constants/resource";
import { RESOURCE_STATUS_LABELS } from "../lang/ja";
import moment from "moment";

const { RESOURCE_STATUS, EXECUTION_TYPES } = require("../constants/status");
const { PROCESS_TYPES } = require("../constants/status");

export const getStatusLabel = (resource) => {
  if (resource.status===RESOURCE_STATUS.PROCESSING) {
    if (resource.process===PROCESS_TYPES.DELETE) {
      return '削除中';
    }
    else { // if (resource.process===PROCESS_TYPES.CREATE)
      return 'ロード中';
    }
  }
  else if (resource.status===RESOURCE_STATUS.WAITING) {
    if (resource.process===PROCESS_TYPES.DELETE) {
      return '削除待ち';
    }
    else { // if (resource.process===PROCESS_TYPES.CREATE)
      return 'ロード待ち';
    }
  }
  return RESOURCE_STATUS_LABELS[resource.status]
}

const WEEKTIME = 7 * 24 * 3600000
export const getProcessTime = (resource) => {
  if (typeof resource.executionDetail !== "object" || resource.executionDetail === null) {
    resource.executionDetail = JSON.parse(resource?.executionDetail)
  }
  if (resource.execution===EXECUTION_TYPES.IMMEDIATE) {
    return null;
  }
  else if (resource.execution===EXECUTION_TYPES.SCHEDULED) {
    if (resource.executionDetail?.scheduledDate) {
      return moment(resource.executionDetail.scheduledDate).format("yyyy/MM/DD HH:mm");
    }
    return null;
  }
  else {
    const now = new Date();
    resource.executionDetail
    let dDaysValue = Object.values(WEEKDAYS).indexOf(resource.executionDetail?.executionDay)+1 
    if(dDaysValue > 7){
      dDaysValue = 0
    }
    const startTime = new Date(resource.executionDetail?.startDate + ' ' + resource.executionDetail?.executionTime);
    let targetDay = new Date(startTime);
    let dDays = dDaysValue - startTime.getDay();
    if (dDays < 0) {
      dDays += 7;
    }
    targetDay.setDate(targetDay.getDate() + dDays);
    let dWeeks = Math.ceil((now.getTime() - targetDay.getTime()) / WEEKTIME);
    dWeeks = dWeeks < 0 ? 0 : dWeeks;
    console.log("datetime local", moment(new Date(targetDay.getTime() + dWeeks * WEEKTIME)).format("yyyy/MM/DD HH:mm"));
    return moment(new Date(targetDay.getTime() + dWeeks * WEEKTIME)).format("yyyy/MM/DD HH:mm")
  }
}