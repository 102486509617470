import PropTypes from 'prop-types';
import React, { Fragment } from "react";
import { Button, Container } from "react-bootstrap";
import "./MessageList.css";
import UpdateAnswerDialog from "./UpdateAnswerDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faThumbsDown, faThumbsUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { 
  listInternalConversationMessages, 
  listExternalConversationMessages, 
  fixChatbotAnswer, 
  adminDeleteConversationMessage } from "../../apis/MessageApi";
import { getInternalResource, getExternalResource } from "../../apis/ResourceApi";
import {useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import moment from 'moment';
import DeleteDialog from './DeleteDialog';

const propTypes = {
  conversation: PropTypes.object,
  onClose: PropTypes.func
};
const defaultProps = {
  conversation: null,
  onClose: ()=>{}
};

const MessageList = (props) => {
  /***** States and Variables *****/
  const { conversation, onClose } = props;
  const [messages, setMessages] = React.useState([]);
  const [selectedMessagePos, setSelectedMessagePos] = React.useState(-1);
  const [faq, setFaq] = React.useState(null);
  const { userInfo } = useSelector((state) => state.users)
  const governmentId = (APP_TYPE === APP_TYPES.ADMIN ? (useParams()?.governmentId || userInfo?.governmentId) : userInfo?.governmentId);
  const [messageResource, setDeleteDiagOpen] = React.useState(null);
  const nextTokenRef = React.useRef(null);
  /***** Processing *****/  
  const downloadMessages = async () => {
    const res = await (
      APP_TYPE===APP_TYPES.INSIDE_ADMIN || APP_TYPE===APP_TYPES.ADMIN? 
      listInternalConversationMessages(conversation.id, null):
      listExternalConversationMessages(conversation.id, null));
    if (res.success) {
      setMessages(res.data);
      nextTokenRef.current = res.nextToken;
    }
  }

  React.useEffect(()=>{
    if (conversation) downloadMessages();
  }, [conversation])
  const urlify = (text)  => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex)
      .map(part => {
        if(part.match(urlRegex)) {
            return <a 
            target='_blank' 
            href={part}
            className='message_list_link'>
              {part}
            </a>;
        }
        return part;
      });
  }
  /***** Sub Components *****/
  const renderText = (text, url) => {
    return <Fragment>
      {text.split('\n').map((subText, i)=>{
          subText = subText.trim();
          return <label 
            key={'lb_' + i + subText}
            className='chatbox_message_text'>
              {urlify(subText)}
            </label>
          }
        )
      }
      {url && url.split('\n').map(part=>{
        part = part.trim();
        if (!part) return null;
        const spacePos = part.indexOf(' ');
        return <div key={part}>
        <a target='_blank'
        href={part}
        className='chatbox_message_text'>
          {spacePos>0? part.substr(0, spacePos): part}
        </a>
        {spacePos>0 && part.substr(spacePos)}
        </div>
      })}
    </Fragment>
  }
  /***** Event Handlers *****/
  const onUpdateAnswer = async (newAnswer, newUrl) => {
    if (!newAnswer.trim()) return;
    const questionMessage = messages[selectedMessagePos-1];
    const answerMessage = messages[selectedMessagePos];
    const res = await fixChatbotAnswer(
      governmentId,
      conversation.id, 
      answerMessage.createdAt,
      faq,
      questionMessage.text, 
      newAnswer, 
      newUrl,
      APP_TYPE===APP_TYPES.OUTSIDE_ADMIN);
    if (res.success) {
      if (res.data) {
        messages.splice(selectedMessagePos, 1, res.data);
        setMessages(Object.assign([], messages));
      }
    }
    setSelectedMessagePos(-1);
  }
  const onSelectMessage = async (pos) => {
    setSelectedMessagePos(pos);
    const selectedMsg = messages[pos];
    if (selectedMsg.fixAnswerCreatedAt) {
      const res = await (
        APP_TYPE===APP_TYPES.INSIDE_ADMIN?
        getInternalResource(governmentId, selectedMsg.fixAnswerCreatedAt):
        getExternalResource(governmentId, selectedMsg.fixAnswerCreatedAt));
      if (res.success) {
        setFaq(res.data);
      }
    }
  }
  const onDownloadMessages = async () => {
    const nextToken = nextTokenRef.current;
    if (nextToken) {
      const res = await (
        APP_TYPE===APP_TYPES.INSIDE_ADMIN? 
        listInternalConversationMessages(conversation.id, nextToken):
        listExternalConversationMessages(conversation.id, nextToken));
      if (res.success) {
        setMessages([...res.data, ...messages]);
        nextTokenRef.current = res.nextToken;
      }
    }
  }
  const onDeleteMessage = async (pos) => {
    const selectedMsg = messages[pos];
    setDeleteDiagOpen(selectedMsg);
  }

  const onDeleteMessageAction = async (message) => {
    const res = await (adminDeleteConversationMessage(conversation.id, message.createdAt, (APP_TYPE===APP_TYPES.INSIDE_ADMIN || APP_TYPE===APP_TYPES.ADMIN)));
    if (res.success) {
        nextTokenRef.current = res.nextToken;
        downloadMessages();
    } 
    // else {
    //   console.log("onDeleteMessageActionErrror: ", res);
    // }
    setDeleteDiagOpen(null);
  }
  /***** Main Render *****/
  const evaluation = conversation.evaluation;
  return <>
    <Container>
      <Button
      className="btn-outline btn-wd mr-1"
      variant="default"
      onClick={onClose}>
        戻る
      </Button>
      <Container className='message_list_general_info_container'>
        <label className='message_list_general_info'>
          評価: &nbsp; {evaluation && evaluation[0]}
        </label>
        <label className='message_list_general_info'>
          問題点: &nbsp; {evaluation && evaluation[1]}
        </label>
        <label className='message_list_general_info'>
          ユーザー: &nbsp; {conversation.ownerId}
        </label>
      </Container>
      {messages[0] && messages[0].createdAt!==conversation.createdAt &&
      <label 
      className='message_list_more'
      onClick={onDownloadMessages}>
        -------------もっと見る-------------
      </label>}
      <Container style={{padding: '0'}}>
        {messages.map((msg, i)=>{
          const isBot = msg.role==="BOT";
          return <div 
          key={msg.conversationId + msg.createdAt} 
          className="message_list_message_container"
          style={{
            justifyContent: isBot? 'flex-start': 'flex-end'
          }}>
            <div className="message_list_message_subcontainer">
              {renderText(msg.text, msg.url)}
              <label 
              className='message_list_datetime'
              style={{
                textAlign: isBot? 'left': 'right'
              }}>
                {moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}
              </label>
              {isBot &&
              <div className="message_list_message_action_container">
                <div className='message_list_like_icon'>
                {msg.like>0? 
                <FontAwesomeIcon icon={faThumbsUp}/>: 
                (msg.like<0 && 
                <FontAwesomeIcon icon={faThumbsDown}/>)}
                </div>
                
                <Button
                className="btn-outline btn-wd mr-1 message_list_message_action"
                variant="default"
                onClick={()=>{onSelectMessage(i)}}>
                  <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
              </div>}
              {(!isBot && (APP_TYPE===APP_TYPES.INSIDE_ADMIN || APP_TYPE===APP_TYPES.ADMIN)) &&
              <div className="message_list_message_action_container">
              <Button
              className="btn-outline btn-wd mr-1 message_list_message_action"
              variant="default"
              onClick={()=>{onDeleteMessage(i)}}>
                 <FontAwesomeIcon icon={faTrash}/>
              </Button>
            </div>}
            </div>
          </div>
        })}
      </Container>
    </Container>
    {(messageResource && (APP_TYPE===APP_TYPES.INSIDE_ADMIN || APP_TYPE===APP_TYPES.ADMIN)) &&
    <DeleteDialog
      resource={messageResource}
      title={"質問削除"}
      message={"この質問を削除します。よろしいでしょうか？"}
      onClose={()=>{setDeleteDiagOpen(null)}}
      onConfirm={()=>{onDeleteMessageAction(messageResource)}}
    />}
    <UpdateAnswerDialog 
    isOpen={selectedMessagePos>=0}
    aiAnswer={messages[selectedMessagePos] && (messages[selectedMessagePos].text + messages[selectedMessagePos].url)}
    answer={faq?.answer}
    question={messages[selectedMessagePos-1] && messages[selectedMessagePos-1].text}
    url={faq?.url}
    onClose={()=>{setSelectedMessagePos(-1); setFaq(null)}}
    onUpdate={onUpdateAnswer}/>
  </>;
}

MessageList.propTypes = propTypes;
MessageList.defaultProps = defaultProps;

export default MessageList;