import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Container, Form } from 'react-bootstrap';
import { Label, Input,  } from "reactstrap";
import "./UpdateAnswerDialog.css";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  question: PropTypes.string,
  answer: PropTypes.string,
  aiAnswer: PropTypes.string,
  url: PropTypes.string,
  editable: PropTypes.bool
}
const defaultProps = {
  isOpen: false,
  onClose: ()=>{},
  onUpdate: (newAnswer, newUrl)=>{},
  question: '',
  answer: '',
  aiAnswer: '',
  url: '',
  editable: true
}

const UpdateAnswerDialog = (props) => {
  /***** Variables And States *****/
  const {isOpen, onClose, onUpdate, question, answer, aiAnswer, url, editable} = props;
  const [fixAnswer, setFixAnswer] = React.useState(answer);
  const [fixUrl, setFixUrl] = React.useState(url);
  /***** Processing *****/
  React.useEffect(()=>{
    setFixAnswer(answer);
  }, [answer]);
  React.useEffect(()=>{
    setFixUrl(url);
  }, [url]);
  /***** Main Render *****/
  if (!isOpen) return null;
  return <div
  className="modal show"
  style={{ display: 'block', position: 'absolute' }}
>
  <Modal.Dialog className="p-0">
    <Modal.Header closeButton onHide={onClose}>
      <Modal.Title className="">修正</Modal.Title>
    </Modal.Header>

    <Modal.Body
    style={{
      maxHeight: '256px',
      overflow: 'auto'
    }}>
      <Container 
      style={{
        paddingBottom: '8px'
      }}
      className="d-flex align-items-center">
        <Label className="w-25 m-0">
          質問
        </Label>
        <Input className="w-75" value={question} disabled={true}/>
      </Container>
      <Container
      className="d-flex align-items-center"
      style={{
        paddingBottom: '8px'
      }}>
        <Label className="w-25 m-0">
          AI回答
        </Label>
        <Form.Control className="w-75" as='textarea' rows={3} value={aiAnswer} disabled={true}/>
      </Container>
      <Container
      className="d-flex align-items-center"
      style={{
        paddingBottom: '8px'
      }}>
        <Label className="w-25 m-0">
          回答
        </Label>
        <Form.Control 
        className="w-75" as='textarea' 
        rows={3} 
        value={fixAnswer}
        disabled={!editable}
        onChange={(e)=>{setFixAnswer(e.target.value)}}/>
      </Container>
      <Container 
      style={{
        paddingBottom: '8px'
      }}
      className="d-flex align-items-center">
        <Label className="w-25 m-0">
          URL
        </Label>
        <Form.Control 
        className="w-75" as='textarea' 
        rows={3} 
        value={fixUrl}
        disabled={!editable}
        onChange={(e)=>{setFixUrl(e.target.value)}}/>
      </Container>
    </Modal.Body>

    <Modal.Footer className="d-flex justify-content-end">
      <Button 
      className="btn-outline btn-wd mr-1"
      variant="default"
      onClick={onClose}>
        キャンセル
      </Button>
      <Button 
      className="btn-outline btn-wd mr-1"
      variant="default"
      disabled={!editable}
      onClick={()=>{onUpdate(fixAnswer, fixUrl)}}>
        保存
      </Button>
    </Modal.Footer>
  </Modal.Dialog>
</div>
}

UpdateAnswerDialog.propTypes = propTypes;
UpdateAnswerDialog.defaultProps = defaultProps;
export default UpdateAnswerDialog;