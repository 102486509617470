const paths = () => {
    return {
        CREATE_GOVERNMENT: '/admin/governments/create',
        LIST_GOVERNMENTS: '/admin/governments',
        CREATE_ACCOUNT: '/admin/governments/:governmentId/accounts/create',
        EDIT_ACCOUNT: '/admin/accounts/:accountId/edit',
        EDIT_GOVERNMENT: '/admin/governments/:governmentId/edit',
        LIST_ACCOUNT: '/admin/governments/:governmentId/accounts',
        LIST_QA:  '/admin/qa_list/:governmentId',
        LOGIN: '/auth/login',
    }
}

export default paths()
