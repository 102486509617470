import React, {useState, useEffect} from 'react';
import {FormGroup, Label, FormFeedback, Button, Row, Col, CustomInput} from 'reactstrap';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';
import prefectures from "../constants/prefectures";
import {registerGovernmentSchema} from "../utils/validationSchemas";
import paths from '../constants/paths';
import {useHistory} from "react-router";
import {Form} from "react-bootstrap";

function GovernmentForm({onSubmit, government = null}) {
    let history = useHistory();

    const {control, handleSubmit, formState: {errors}, setValue, watch} = useForm({
        resolver: yupResolver(registerGovernmentSchema),
        defaultValues: government ? {
            governmentName: government.governmentName,
            prefecture: government.prefecture,
            maxExternalMsgPerUser: government.maxExternalMsgPerUser,
            maxExternalMsgPerGovernment: government.maxExternalMsgPerGovernment,
            maxInternalMsgPerUser: government.maxInternalMsgPerUser,
            maxInternalMsgPerGovernment: government.maxInternalMsgPerGovernment,
            ipWhitelist: government.ipWhitelist,
            status: government.status === 1
        } : {}
    });

    const ipWhitelistValues = watch('ipWhitelist', government && government.ipWhitelist ? government.ipWhitelist : [null]);

    const handleAddIpInput = () => {
        setValue('ipWhitelist', [...ipWhitelistValues, '']);
    };

    const handleRemoveIpInput = (indexToRemove) => {
        const newIpWhitelistValues = [...ipWhitelistValues].filter((_, index) => index !== indexToRemove);

        setValue('ipWhitelist', newIpWhitelistValues);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {government  && <FormGroup>
                <Controller
                    name="status"
                    control={control}
                    render={({field}) =>
                        <CustomInput
                            type="switch"
                            {...field}
                            id="governmentStatus"
                            name="status"
                            checked={field.value}
                            onChange={() => {field.onChange(!field.value)}}
                        />
                    }
                />

            </FormGroup> }

            <FormGroup>
                <Label for="governmentName">自治体名</Label>
                <Controller
                    name="governmentName"
                    control={control}
                    render={({field}) =>
                        <Form.Control {...field} type="text" isInvalid={errors.governmentName}/>
                    }
                />
                {errors.governmentName && <FormFeedback>{errors.governmentName.message}</FormFeedback>}
            </FormGroup>

            <FormGroup>
                <Label for="prefecture">都道府果</Label>
                <Controller
                    name="prefecture"
                    control={control}
                    render={({field}) =>
                        <Form.Control as="select" {...field} isInvalid={errors.prefecture}>
                            <option value={null}></option>
                            {prefectures.map(item => <option key={item} value={item}>{item}</option>)}
                        </Form.Control>
                    }
                />
                {errors.prefecture && <FormFeedback>{errors.prefecture.message}</FormFeedback>}
            </FormGroup>
            <Row>
                <Col>
                    外部向け上限設定
                </Col>
            </Row>
            <FormGroup>
                <Label for="maxExternalMsgPerUser">質周数/人</Label>
                <Controller
                    name="maxExternalMsgPerUser"
                    control={control}
                    render={({field}) =>
                        <Form.Control {...field} type="number" isInvalid={errors.maxExternalMsgPerUser}/>
                    }
                />
                {errors.maxExternalMsgPerUser &&
                    <FormFeedback>{errors.maxExternalMsgPerUser.message}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label for="maxExternalMsgPerGovernment">質間数白治体</Label>
                <Controller
                    name="maxExternalMsgPerGovernment"
                    control={control}
                    render={({field}) =>
                        <Form.Control {...field} type="number" isInvalid={errors.maxExternalMsgPerGovernment}/>
                    }
                />
                {errors.maxExternalMsgPerGovernment &&
                    <FormFeedback>{errors.maxExternalMsgPerGovernment.message}</FormFeedback>}
            </FormGroup>
            <Row>
                <Col>
                    内部向け上限設定
                </Col>
            </Row>
            <FormGroup>
                <Label for="maxInternalMsgPerUser">質周数/人</Label>
                <Controller
                    name="maxInternalMsgPerUser"
                    control={control}
                    render={({field}) =>
                        <Form.Control {...field} type="number" isInvalid={errors.maxInternalMsgPerUser}/>
                    }
                />
                {errors.maxInternalMsgPerUser &&
                    <FormFeedback>{errors.maxInternalMsgPerUser.message}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label for="maxInternalMsgPerGovernment">質間数白治体</Label>
                <Controller
                    name="maxInternalMsgPerGovernment"
                    control={control}
                    render={({field}) =>
                        <Form.Control {...field} type="number" isInvalid={errors.maxInternalMsgPerGovernment}/>
                    }
                />
                {errors.maxInternalMsgPerGovernment &&
                    <FormFeedback>{errors.maxInternalMsgPerGovernment.message}</FormFeedback>}
            </FormGroup>

            <FormGroup>
                <Label for={`ipWhitelist`}>IPホワイトリスト</Label>
                {ipWhitelistValues.map((ip, index) => (
                    <Row key={index} className="align-items-center mb-2">
                        <Col xs="6">
                            <Controller
                                name={`ipWhitelist[${index}]`}
                                control={control}
                                defaultValue={ip}
                                render={({field}) =>
                                    <Form.Control {...field} type="text" value={ip}    onChange={e => {
                                        field.onChange(e);
                                        const newIps = [...ipWhitelistValues];
                                        newIps[index] = e.target.value;
                                        setValue('ipWhitelist', newIps);
                                    }} isInvalid={errors.ipWhitelist && errors.ipWhitelist[index]}/>
                                }
                            />
                            {errors.ipWhitelist && errors.ipWhitelist[index] && <FormFeedback>{errors.ipWhitelist[index].message}</FormFeedback>}
                        </Col>
                        <Col xs="2">
                            <button type="button" style={buttonStyle} onClick={() => handleRemoveIpInput(index)}><FontAwesomeIcon icon={faMinus}/></button>
                        </Col>
                    </Row>
                ))}
                <button type="button" style={buttonMinusStyle} onClick={handleAddIpInput}><FontAwesomeIcon icon={faPlus}/></button>
            </FormGroup>
            <Button type="submit">保存</Button>
            <button onClick={() => history.push(paths.LIST_GOVERNMENTS)} className="btn btn-outline ml-2">キャンセル</button>
        </Form>
    );
}

const buttonMinusStyle = {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '1rem',
    width: '150px',
    height: '30px',
    border: '1px solid #e2e2e2',
};

const buttonStyle = {
    borderRadius: '50%',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '1rem',
    width: '50px',
    height: '50px',
    border: '1px solid #e2e2e2'
};

export default GovernmentForm;
