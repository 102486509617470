import React, {useEffect} from 'react';
import {useHistory} from "react-router";
import {useDispatch, useSelector} from 'react-redux';
import {Auth} from 'aws-amplify';

const PrivateRoute = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.users.userInfo);

    const checkSession = async () => {
        try {
            await Auth.currentSession();
        } catch (error) {
            localStorage.clear();
            history.push('/auth/login');
        }
    };

    useEffect(() => {
        checkSession();
    }, [dispatch,history]);

    if (!userInfo) {
        localStorage.clear();
        history.push('/auth/login');
        return null;
    }

    return <>{props.children}</>;
};

export default PrivateRoute;
