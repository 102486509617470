import React, {useState, useEffect} from "react";
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    Card,
    Form,
    Container,
    Col,
} from "react-bootstrap";
import {loginSchema} from "../utils/validationSchemas";
import {useDispatch, useSelector} from 'react-redux';
import {userLogin} from "../features/user/userActions";
import {useHistory} from "react-router";
import Button from "@mui/material/Button";
import {APP_TYPE, APP_TYPES} from "../constants/app.js";

function Login() {
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const { loading, userInfo, error } = useSelector((state) => state.users)
    const {handleSubmit, control, formState: {errors}} = useForm({
        resolver: yupResolver(loginSchema)
    });
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (userInfo) {
            APP_TYPE !== APP_TYPES.ADMIN?
            history.replace('/admin/qa_list'):history.replace('/admin')
        }
    }, [history, userInfo])

    useEffect(()=>{
        if (error && error === 'NEW_PASSWORD_REQUIRED') {
            setNewPasswordRequired(true);
        }
    },[error])

    const getIPFromIpify = () => {
        return fetch('https://api.ipify.org?format=json').then(response => response.json()).then(data => data.ip);
    };

    const getIPFromGeolocationDB = () => {
        return fetch('https://geolocation-db.com/json').then(response => response.json()).then(data => data.IPv4);
    };

    const getIPAddress = async () => {
        try {
            return await getIPFromIpify();
        } catch (error) {
            return getIPFromGeolocationDB();
        }
    };

    const onSubmit = (data) => {
        getIPAddress()
            .then(ipAddress => {
                const updatedData = { ...data, ipAddress };

                dispatch(userLogin(updatedData));
            })
            .catch(error => {
                console.error("Cannot get IP address:", error);
            });
    };

    return <div className="full-page section-image" data-color="black"
                data-image={require("assets/img/full-screen-image-2.jpg")}>
        <div className="content d-flex align-items-center p-0">
            <Container>
                <Col className="mx-auto" lg="4" md="8">
                    <Form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <Card className={"card-login "}>
                            <Card.Header>
                                <h3 className="header text-center">Login</h3>
                            </Card.Header>
                            <Card.Body>
                                <Card.Body>
                                    {newPasswordRequired ?
                                        <Form.Group>
                                            <label>新しいパスワード</label>
                                            <Controller
                                                name="newPassword"
                                                control={control}
                                                defaultValue=""
                                                render={({field}) => <Form.Control {...field} type="password"
                                                                                   isInvalid={errors.newPassword}/>}
                                            />
                                            {errors.newPassword && <Form.Control.Feedback
                                                type="invalid">{errors.newPassword.message}</Form.Control.Feedback>}
                                        </Form.Group> : <>
                                            <Form.Group>
                                                <label>ユーザID</label>
                                                <Controller
                                                    name="username"
                                                    control={control}
                                                    render={({field}) => <Form.Control {...field} type="text"
                                                                                       isInvalid={errors.username}/>}
                                                />
                                                {errors.username && <Form.Control.Feedback
                                                    type="invalid">{errors.username.message}</Form.Control.Feedback>}
                                            </Form.Group>
                                            <Form.Group>
                                                <label>パスワード</label>
                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({field}) => <Form.Control {...field}
                                                                                       type="password"
                                                                                       placeholder="Password"
                                                                                       isInvalid={errors.password}/>}
                                                />
                                                {errors.password && <Form.Control.Feedback
                                                    type="invalid">{errors.password.message}</Form.Control.Feedback>}
                                            </Form.Group>
                                        </>
                                    }

                                    <Form.Group>
                                        <div className="d-flex flex-column align-content-center">
                                            <Button variant="contained" disabled={loading} className="btn-wd" type="submit">Login</Button>

                                            {(error && error !== 'NEW_PASSWORD_REQUIRED') &&
                                                <span className="red">{error}</span>}
                                        </div>
                                    </Form.Group>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Container>
        </div>
        <div className="full-page-background"
             style={{backgroundImage: "url(" + require("assets/img/full-screen-image-2.jpg") + ")"}}></div>
    </div>
}

export default Login;
