import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormGroup, Label } from 'reactstrap';
import { Form } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import {ADMIN_CREATE_ROLES} from "../constants/roles";
import { Input } from '@mui/material';
import "./AccountForm.css";

const AccountForm = ({ onSubmit, defaultValues, resolver, loading, error,isEdit }) => {
    const history = useHistory();
    const { register, handleSubmit, formState: { errors, isDirty }, control } = useForm({
        defaultValues,
        resolver: resolver ? yupResolver(resolver) : undefined,
    });
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Username field */}
            <FormGroup className='d-flex flex-column'>
                <Label for="username">ユーザID</Label>
                <input
                className='account_form_input' 
                disabled={isEdit} 
                type="text" 
                isInvalid={errors.username}
                {...register('username')} />
                {errors.username && <Form.Control.Feedback type="invalid">{errors.username.message}</Form.Control.Feedback>}
            </FormGroup>

            {/* Email field */}
            <FormGroup className='d-flex flex-column'>
                <Label for="email">メールアドレス</Label>
                <input
                className='account_form_input' 
                disabled={isEdit} 
                type="text" 
                isInvalid={errors.email}
                {...register('email')} />
                {errors.email && <Form.Control.Feedback type="invalid">{errors.email.message}</Form.Control.Feedback>}
            </FormGroup>

            {/* Password field */}
            {<FormGroup>
                <Label for="password">{isEdit ? '新しいパスワード（もしあれば）' : '初回パスワード'}</Label>
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => <Form.Control {...field} type="password" isInvalid={errors.password} />}
                />
                {errors.password && <Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>}
            </FormGroup>}

            {/* Role selection */}
            <FormGroup>
                <Form.Label>グループ</Form.Label>
                <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                        <>
                            {ADMIN_CREATE_ROLES.map(role => (
                                <Form.Check
                                    key={role.id}
                                    {...field}
                                    type="radio"
                                    id={role.id}
                                    label={role.label}
                                    value={role.id}
                                    checked={field.value === role.id}
                                />
                            ))}
                        </>
                    )}
                />
                {errors.role && <Form.Control.Feedback style={{display: 'block'}} type="invalid">{errors.role.message}</Form.Control.Feedback>}
            </FormGroup>

            {/* Submit and cancel buttons */}
            <FormGroup>
                <Button disabled={loading || !isDirty} variant="contained" type="submit">保存</Button>
                <Button className="ml-2" variant="outlined" onClick={() => history.goBack()}>キャンセル</Button>
            </FormGroup>

            {/* Error message */}
            {error && <span style={{color: "red"}} className="mb-2">{error}</span>}
        </Form>
    );
};

export default AccountForm;
