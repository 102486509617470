import * as yup from 'yup';
import { VALIDATION_MESSAGES } from '../lang/ja.js';
import {ROLES} from '../constants/roles'

const ROLE_IDS = ROLES.map(role => role.id);

export const registerSchema = yup.object().shape({
    email: yup.string().email(VALIDATION_MESSAGES.INVALID_EMAIL_FORMAT).required(VALIDATION_MESSAGES.EMAIL_REQUIRED),
    password: yup.string().min(8, VALIDATION_MESSAGES.PASSWORD_MIN_8).required(VALIDATION_MESSAGES.PASSWORD_REQUIRED),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], VALIDATION_MESSAGES.PASSWORDS_MUST_MATCH)
        .required(VALIDATION_MESSAGES.PASSWORD_CONFIRMATION_REQUIRED)
});

export const loginSchema = yup.object().shape({
    username: yup.string()
        .matches(/^\S*$/, VALIDATION_MESSAGES.USERNAME_SPACES)
        .required(VALIDATION_MESSAGES.USERNAME_REQUIRED),
    password: yup.string()
        .matches(/^\S*$/, VALIDATION_MESSAGES.USERNAME_SPACES)
        .required(VALIDATION_MESSAGES.PASSWORD_REQUIRED)
});

export const registerAccountSchema = yup.object().shape({
    username: yup.string().required(VALIDATION_MESSAGES.USERNAME_REQUIRED),
    email: yup.string().email(VALIDATION_MESSAGES.INVALID_EMAIL_FORMAT).required(VALIDATION_MESSAGES.EMAIL_REQUIRED),
    password: yup.string().min(8, VALIDATION_MESSAGES.PASSWORD_MIN_8).required(VALIDATION_MESSAGES.PASSWORD_REQUIRED),
    role: yup.string().oneOf(ROLE_IDS, VALIDATION_MESSAGES.INVALID_ROLE).required(VALIDATION_MESSAGES.ROLE_REQUIRED)
})

export const editAccountSchema = yup.object().shape({
    role: yup.string().oneOf(ROLE_IDS, VALIDATION_MESSAGES.INVALID_ROLE).required(VALIDATION_MESSAGES.ROLE_REQUIRED)
})

export const registerGovernmentSchema = yup.object().shape({
    governmentName: yup.string().required(VALIDATION_MESSAGES.GOVERNMENT_NAME_REQUIRED),
    prefecture: yup.string().required(VALIDATION_MESSAGES.PREFECTURE_REQUIRED),
    maxExternalMsgPerUser: yup.number()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, VALIDATION_MESSAGES.VALUE_MUST_NOT_BE_NEGATIVE)
        .required(VALIDATION_MESSAGES.MAX_EXT_MSG_USER_REQUIRED),
    maxExternalMsgPerGovernment: yup.number()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, VALIDATION_MESSAGES.VALUE_MUST_NOT_BE_NEGATIVE)
        .required(VALIDATION_MESSAGES.MAX_EXT_MSG_GOVERNMENT_REQUIRED),
    maxInternalMsgPerUser: yup.number()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, VALIDATION_MESSAGES.VALUE_MUST_NOT_BE_NEGATIVE)
        .required(VALIDATION_MESSAGES.MAX_INT_MSG_USER_REQUIRED),
    maxInternalMsgPerGovernment: yup.number()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, VALIDATION_MESSAGES.VALUE_MUST_NOT_BE_NEGATIVE)
        .required(VALIDATION_MESSAGES.MAX_INT_MSG_GOVERNMENT_REQUIRED),
    ipWhitelist: yup.array().of(yup.string().required(VALIDATION_MESSAGES.IP_REQUIRED).matches(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/, VALIDATION_MESSAGES.INVALID_IP_FORMAT))
});