import React, {useEffect, useState} from "react";
import {
    Card,
    Table,
} from "react-bootstrap";
import {API, graphqlOperation} from 'aws-amplify';
import {listGovernments} from "../../graphql/queries";
import {Col, Button, Row} from 'reactstrap';
import {GOVERNMENT_STATUS} from "../../constants/status";
import {useHistory} from "react-router";
import paths from "../../constants/paths";
import {BUTTON_LABELS} from "../../lang/ja";
import PaginationComponent from '../../components/PaginationComponent';
import {NUM_ITEMS_PER_PAGE} from "../../constants/table";
import {useSelector} from 'react-redux';
const Governments = () => {
    const [governments, setGovernments] = React.useState([]);
    let history = useHistory();
    const [currentPage, setCurrentPage] = useState(0);
    const [nextToken, setNextToken] = useState(null);
    const [totalPage, setTotalPage] = useState(0)
    const { userInfo } = useSelector((state) => state.users)
    const fetchGovernments = async (shouldAppend = false) => {
        try {
            const variables = {
                nextToken,
            };

            const {data} = await API.graphql({
                query:listGovernments,
                variables
            });
            const governmentsData = data?.listGovernments
            if(governmentsData){
                const newGovernments = governmentsData?.items;
                const allGovernments = shouldAppend ? [...governments,...newGovernments] : newGovernments
                setGovernments(allGovernments);
                setNextToken(governmentsData.nextToken || null);
                setTotalPage(Math.ceil(allGovernments.length / NUM_ITEMS_PER_PAGE));
            }

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchGovernments();
    }, [])


    const handleChangePage = async (newPage) => {
        if (newPage +1 === totalPage && nextToken) {
            await fetchGovernments(true);
        }
        setCurrentPage(newPage);
    };

    return <>
        <Col md="12">
            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Card.Title as="h4">自治体リスト</Card.Title>
                    <div className="mb-2 d-flex flex-row justify-content-between align-items-center">
                        <PaginationComponent
                            currentPage={currentPage}
                            totalItems={governments ? governments.length : 0}
                            itemsPerPage={NUM_ITEMS_PER_PAGE}
                            onPageChange={handleChangePage}
                        />
                        <Button
                            className="btn-outline btn-wd mr-1"
                            variant="default"
                            onClick={() => history.push(paths.CREATE_GOVERNMENT)}
                        >
                            {BUTTON_LABELS.BUTTON_REGISTER_NEW}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                    <Table className="table-hover table-striped w-full">
                        <thead>
                        <tr>
                            <th>自治体名</th>
                            <th>都道府県</th>
                            <th style={{textAlign: 'center'}}>月上限回答数人</th>
                            <th style={{textAlign: 'center'}}>有効</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {governments.map((government, index) => {
                            if (index < currentPage * NUM_ITEMS_PER_PAGE || index >= (currentPage + 1) * NUM_ITEMS_PER_PAGE) return null;
                            return (
                                <tr key={index}>
                                    <td>{government.governmentName}</td>
                                    <td>{government.prefecture}</td>
                                    <td style={{textAlign: 'center'}}>{government.maxExternalMsgPerUser}</td>
                                    <td style={{textAlign: 'center'}}>{government.status && government.status === GOVERNMENT_STATUS.active ? 'ON' : 'OFF'}</td>
                                    <td>
                                        <Button className="mr-3 btn btn-outline"
                                                onClick={() => history.push(`/admin/governments/${government.governmentId}/edit`)}>詳細</Button>
                                        <Button className="mr-3 btn btn-outline"
                                                onClick={() => history.push(`/admin/governments/${government.governmentId}/accounts`)}> アカウント</Button>
                                        <Button className="btn btn-outline"
                                                onClick={() => history.push(`/admin/qa_list/${government.governmentId}`)}> QAリスト</Button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
    </>;
}

export default Governments;
