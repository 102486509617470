// TODO: TreeView + TreeViewItem
// https://v4.framework7.io/react/treeview
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Table,
  Col,
} from "react-bootstrap";
import WebRegisterDialog from './WebRegisterDialog';
import { BUTTON_LABELS, RESOURCE_STATUS_LABELS, MESSAGES, WEBPAGE_STATUSES_LABELS } from '../../lang/ja';
import {
  createWebPage,
  updateBatchRecurringWebPage,
  processManualWebPage, 
  rejectProcessingWebpage, 
  rejectReprocessingWebpage,
  requestDeletingWebPage, 
  requestProcessingWebPage, 
  requestReprocessingWebPage,
  updateWebPage,
  startDeletingWebpage,
  adminDeleteWebpage,
  deleteWebPage,
  searchWebPages
} from '../../apis/ResourceApi';
import PaginationComponent from '../../components/PaginationComponent';
import BatchModalDialog from "../../components/BatchModalDialog";
import RejectResourceDialog from '../../pages/resource/RejectResourceDialog';
import { NUM_ITEMS_PER_PAGE } from '../../constants/table';
import moment from 'moment';
import { RESOURCE_STATUS, WEBPAGE_STATUSES } from '../../constants/status';
import FanLoadingIcon from '../../components/FanLoadingIcon';
import { APP_TYPE, APP_TYPES } from '../../constants/app';
import { useDialog } from 'contexts/DialogContext';
import "./WebPageTable.css";
import { Input } from 'reactstrap';
import { getProcessTime } from "utils/resources";

const propTypes = {
  onBack: PropTypes.func,
  web: PropTypes.object,
  isAdminTable: PropTypes.bool
};
const defaultProps = {
  onBack: () => {},
  web: null,
  isAdminTable: false
};
const WebPageTable = (props) => {
  /***** States And Variables *****/
  const { web, onBack, isAdminTable } = props;
  const [webpages, setWebpages] = React.useState([]);
  const [curPage, setCurPage] = React.useState(0);
  const [selectedWebpage, setSelectedWebpage] = React.useState(null);
  const [processRejectedWebpage, setProcessRejectedWebpage] = React.useState(null);
  const [deleteRejectedWebpage, setDeleteRejectedWebpage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const nextTokenRef = React.useRef(null);
  const { success, error } = useDialog();
  const [searchCond, setSearchCond] = React.useState({keyword: '', status: ''});
  const [selectedWebPageForBatch, setSelectedWebPageForBatch] = React.useState(null);
  /***** Processing *****/
  React.useEffect(()=>{
    const downloadWebPages = async () => {
      const res = await searchWebPages(web.id, '', '', 2 * NUM_ITEMS_PER_PAGE, null);
      if (res.success){
        console.log("res",res.data)
        setWebpages(res.data);
        nextTokenRef.current = res.nextToken;
      }
    }
    downloadWebPages();
  }, [web])

  /***** Event Handlers *****/
  const onRequestProcess = async (page) => {
    if (loading) return;
    setLoading(true);
    const res = await requestProcessingWebPage(page.baseUrlId, page.detailUrl);
    if (res.success) {
      const pos = webpages.indexOf(page);
      if (pos>=0) {
        webpages.splice(pos, 1, res.data);
        setWebpages(Object.assign([], webpages));
      }
    }
    setLoading(false);
  }
  const onRequestReprocess = async (page) => {
    if (loading) return;
    setLoading(true);
    const res = await requestReprocessingWebPage(page.baseUrlId, page.detailUrl);
    if (res.success) {
      const pos = webpages.indexOf(page);
      if (pos>=0) {
        webpages.splice(pos, 1, res.data);
        setWebpages(Object.assign([], webpages));
      }
    }
    setLoading(false);
  }
  const onRejectProcess = async (reason) => {
    if (loading) return;
    setLoading(true);
    const res = await (
      processRejectedWebpage?
      rejectProcessingWebpage(processRejectedWebpage.baseUrlId, processRejectedWebpage.detailUrl, reason):
      rejectDeletingWebpage(deleteRejectedWebpage.baseUrlId, deleteRejectedWebpage.detailUrl, reason));
    if (res.success) {
      const pos = webpages.indexOf(processRejectedWebpage || deleteRejectedWebpage);
      if (pos>=0) {
        webpages.splice(pos, 1, res.data);
        setWebpages(Object.assign([], webpages));
      }
    }
    setLoading(false);
    setProcessRejectedWebpage(null);
    setDeleteRejectedWebpage(null);
  }
  const onRejectReprocess = async (page) => {
    if (loading) return;
    setLoading(true);
    const res = await rejectReprocessingWebpage(page.baseUrlId, page.detailUrl);
    if (res.success) {
      const pos = webpages.indexOf(page);
      if (pos>=0) {
        webpages.splice(pos, 1, res.data);
        setWebpages(Object.assign([], webpages));
      }
    }
    setLoading(false);
    setProcessRejectedWebpage(null);
  }
  const onUpdateResourceExecution = async (executionType, executionDetail) => {
    if (loading) return;
    setLoading(true);
    executionDetail=JSON.stringify(executionDetail)
    console.log("selectedWebPageForBatch",selectedWebPageForBatch)
    const page = selectedWebPageForBatch;
    const res = await updateBatchRecurringWebPage(page, {execution: executionType, executionDetail});
    if (res.success) {
        success(MESSAGES.EXECUTION_UPDATED_SUCCESSFULLY);
        webpages.splice(webpages.indexOf(page), 1, res.data);
        setWebpages(Object.assign([], webpages));
    }
    else {
        error(MESSAGES.SOMETHING_WRONG);
    }
    setSelectedWebPageForBatch(null);
    setLoading(false);
  }
  const onRegisterPage = async (url) => {
    if (loading) return;
    const baseUrl = new URL(web.name);
    const newUrl  = new URL(url);
    if (baseUrl.origin!==newUrl.origin) return;
    const newDetailUrl = newUrl.pathname;
    setLoading(true);
    console.log("selectedWebpage",web)
    if (selectedWebpage.id) {
      const res = await updateWebPage(selectedWebpage.baseUrlId, selectedWebpage.detailUrl, newDetailUrl,  {execution: executionType, executionDetail});
      if (res.success) {
        const pos = webpages.indexOf(selectedWebpage);
        if (pos>=0) {
          webpages.splice(pos, 1, res.data);
          setWebpages(Object.assign([], webpages));
        }
      }
    }
    else {
      let URLtype = ''
      const res = await createWebPage(web.id, newDetailUrl, URLtype = 'PRIVATE');
      console.log("res",res)
      if (res.success) {
        const newPage = res.data;
        success(MESSAGES.URL_ALREADY_REGISTERED)
        webpages.push(newPage);
        webpages.sort((p1, p2)=>{
          return p1.detailUrl.localeCompare(p2.detailUrl);
        });
        const pos = webpages.indexOf(newPage);
        setCurPage(Math.floor(pos/NUM_ITEMS_PER_PAGE));
        setWebpages(Object.assign([], webpages));
      }
    }
    setLoading(false);
    setSelectedWebpage(null);
  }
  const onPageChange = async (page) => {
    if (loading) return;
    setLoading(true);
    setCurPage(page);
    const numPages = Math.ceil(webpages.length/NUM_ITEMS_PER_PAGE);
    if (page+1===numPages) {
      const nextToken = nextTokenRef.current;
      if (nextToken) {
        const res = await searchWebPages(web.id, searchCond.keyword, searchCond.status, 2 * NUM_ITEMS_PER_PAGE, nextToken);
        if (res.success) {
          setWebpages([...webpages, ...res.data]);
          nextTokenRef.current = res.nextToken;
        }
      }
    }
    setLoading(false);
  }
  const onProcess = async (page) => {
    if (loading) return;
    setLoading(true);
    const res = await processManualWebPage(web.governmentId, web.name, page.detailUrl, APP_TYPE!==APP_TYPES.INSIDE_ADMIN);
    if (res.success) {
      const pos = webpages.indexOf(page);
      webpages.splice(pos, 1, res.data);
      setWebpages(Object.assign([], webpages));
    }
    setLoading(false);
  }
  const onRequestDelete = async (page) => {
    if (loading) return;
    setLoading(true);
    const res = await requestDeletingWebPage(page.baseUrlId, page.detailUrl);
    if (res.success) {
      const pos = webpages.indexOf(page);
      webpages.splice(pos, 1, res.data);
      setWebpages(Object.assign([], webpages));
    }
    setLoading(false);
  }
  const onRemoveWebPage = async (page) => {
    if (loading) return;
    setLoading(true);
    const pos = webpages.indexOf(page);
    const startRes = await startDeletingWebpage(page.baseUrlId, page.detailUrl);
    if (startRes.success) {
      webpages.splice(pos, 1, startRes.data);
      setWebpages(Object.assign([], webpages));
      const delRes = await deleteWebPage(web.governmentId, web.name, page.detailUrl, APP_TYPE!==APP_TYPES.INSIDE_ADMIN);
      if (delRes.success) {
        webpages.splice(pos, 1);
        setWebpages(Object.assign([], webpages));
        success(MESSAGES.DELETED_WEB_SUCCESSFULLY);
      }
      else {
        error(MESSAGES.SOMETHING_WRONG);
      }
    }
    else {
      error(MESSAGES.SOMETHING_WRONG);
    }
    setLoading(false);
  }
  const onAdminRemoveWebPage = async (page) => {
    if (loading) return;
    setLoading(true);
    const pos = webpages.indexOf(page);
    const startRes = await startDeletingWebpage(page.baseUrlId, page.detailUrl);
    if (startRes.success) {
      webpages.splice(pos, 1, startRes.data);
      setWebpages(Object.assign([], webpages));
      const delRes = await adminDeleteWebpage(web.governmentId, web.name, page.detailUrl, APP_TYPE!==APP_TYPES.INSIDE_ADMIN);
      if (delRes.success && delRes.data.status!=='FAILED') {
        webpages.splice(pos, 1);
        setWebpages(Object.assign([], webpages));
        success(MESSAGES.DELETED_WEB_SUCCESSFULLY);
      }
      else {
        error(MESSAGES.SOMETHING_WRONG);
      }
    }
    else {
      error(MESSAGES.SOMETHING_WRONG);
    }
    setLoading(false);
  }
  const onSearch = async () => {
    if (loading) return;
    setLoading(true);
    nextTokenRef.current = null;
    const res = await searchWebPages(web.id, searchCond.keyword, searchCond.status, 2 * NUM_ITEMS_PER_PAGE, nextTokenRef.current);
    if (res.success) {
      setCurPage(0);
      setWebpages(res.data);
      nextTokenRef.current = res.nextToken;
    }
    else {
      error(MESSAGES.SOMETHING_WRONG);
    }
    setLoading(false);
  }
  /***** Main Render *****/
  return <>
    <Col md="12">
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">データ管理 &gt; WEBサイト</Card.Title>
          <p className="card-category">
            {web.name}
          </p>
          <div className="d-flex flex-row justify-content-between align-items-end">
            <PaginationComponent 
              currentPage={curPage}
              totalItems={webpages.length}
              onPageChange={onPageChange}/>
            <div>
              <Button
                className="btn-outline btn-wd mr-1"
                variant="default"
                onClick={onBack}>
                {BUTTON_LABELS.BUTTON_BACK}
              </Button>
              <Button
                className="btn-outline btn-wd mr-1"
                variant="default"
                onClick={()=>{setSelectedWebpage({})}}>
                {BUTTON_LABELS.BUTTON_REGISTER_NEW}
              </Button>
            </div>
          </div>
          <div 
          style={{
            paddingTop: 8
          }}
          className="d-flex flex-row justify-content-between align-items-end">
            <div className='web_page_table_search_container'>
              <Input
              className='web_page_table_search_input'
              value={searchCond.keyword}
              onKeyDown={(e)=>{if(e.key==='Enter') onSearch()}}
              onChange={(e)=>{setSearchCond({keyword: e.target.value, status: searchCond.status})}}/>
              <select 
              className='web_page_table_status_select'
              value={searchCond.status}
              onChange={(e)=>setSearchCond({keyword: searchCond.keyword, status: e.target.value})}>
                <option value=''></option>
                {Object.keys(WEBPAGE_STATUSES).map(s=>{
                  return <option value={s} key={s}>
                    {WEBPAGE_STATUSES_LABELS[s]}
                  </option>
                })}
              </select>
            </div>
            <Button
              className="btn-outline btn-wd mr-1 mb-0"
              variant="default"
              onClick={onSearch}>
              {BUTTON_LABELS.BUTTON_SEARCH}
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="table-responsive p-0">
          <Table className="table-hover table-striped w-full">
            <thead>
              <tr>
                <th className="common_table_name_cell">URL</th>
                <th>作成日時</th>
                <th>反映予定日時</th>
                <th>ステータス</th>
                <th>アクション</th>
              </tr>
            </thead>
            <tbody>
              {webpages.map((page, i)=>{
                if (i<curPage*NUM_ITEMS_PER_PAGE || i>=(curPage+1)*NUM_ITEMS_PER_PAGE) return null;
                const status = page.status;
                return <tr>
                  <td className='web_page_table_detail_url_cell'>
                    {page.detailUrl}
                  </td>
                  <td>{moment(page.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                  <td>{getProcessTime(page)}</td>
                  <td>
                    {RESOURCE_STATUS_LABELS[status]}
                    {page.rejectReason &&
                    <button 
                    className="button_reject_reason"
                    onClick={()=>{setProcessRejectedWebpage(page)}}>
                      !
                    </button>}
                  </td>
                  <td><>
                    {/**** EDIT ****/}
                    {(status===RESOURCE_STATUS.PENDING || status===RESOURCE_STATUS.PROCESS_REJECTED || status===RESOURCE_STATUS.SCRAPE_FAILED) &&
                      <Button
                      className="btn-outline mr-1"
                      variant="default"
                      onClick={()=>{setSelectedWebpage(page)}}>
                      {BUTTON_LABELS.BUTTON_EDIT}
                    </Button>}
                    {/**** REQUEST PROCESS ****/}
                    {!isAdminTable && status===RESOURCE_STATUS.PENDING &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onRequestProcess(page)}}>
                        {BUTTON_LABELS.BUTTON_REQUEST_PROCESS}
                      </Button>}
                    {/**** REQUEST REPROCESS ****/}
                    {!isAdminTable && (
                      status===RESOURCE_STATUS.SCRAPE_FAILED || 
                      status===RESOURCE_STATUS.S3_FAILED || 
                      status===RESOURCE_STATUS.FAILED) &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onRequestReprocess(page)}}>
                        {BUTTON_LABELS.BUTTON_REQUEST_REPROCESS}
                      </Button>}
                    {/**** ACCEPT PROCESS ****/}
                    {isAdminTable && status===RESOURCE_STATUS.PROCESS_REQUESTING &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onProcess(page)}}>
                        {BUTTON_LABELS.BUTTON_ACCEPT_PROCESS}
                      </Button>}
                    {/**** ACCEPT REPROCESS ****/}
                    {isAdminTable && (
                      status===RESOURCE_STATUS.SCRAPE_FAILED ||
                      status===RESOURCE_STATUS.S3_FAILED || 
                      status===RESOURCE_STATUS.FAILED || 
                      status===RESOURCE_STATUS.REPROCESS_REQUESTING) &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onProcess(page)}}>
                        {BUTTON_LABELS.BUTTON_ACCEPT_REPROCESS}
                      </Button>}
                    {/**** REJECT PROCESS ****/}
                    {isAdminTable && status===RESOURCE_STATUS.PROCESS_REQUESTING &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{setProcessRejectedWebpage(page)}}>
                        {BUTTON_LABELS.BUTTON_REJECT_PROCESS}
                      </Button>}
                    {/**** REJECT REPROCESS ****/}
                    {isAdminTable && status===RESOURCE_STATUS.REPROCESS_REQUESTING &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onRejectReprocess(page)}}>
                        {BUTTON_LABELS.BUTTON_REJECT_REPROCESS}
                      </Button>}
                    {/**** REQUEST DELETE ****/}
                    {!isAdminTable && status===RESOURCE_STATUS.FINISHED &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onRequestDelete(page)}}>
                        {BUTTON_LABELS.BUTTON_REQUEST_DELETE}
                      </Button>}
                    {/**** REJECT DELETE ****/}
                    {isAdminTable && status===RESOURCE_STATUS.DELETE_REQUESTING &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{setProcessRejectedWebpage(page)}}>
                        {BUTTON_LABELS.BUTTON_REJECT_DELETE}
                      </Button>}
                    {/**** DELETE FOR STAFF ****/}
                    {!isAdminTable && (status===RESOURCE_STATUS.PENDING || status===RESOURCE_STATUS.PROCESS_REJECTED) &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onRemoveWebPage(page)}}>
                        {BUTTON_LABELS.BUTTON_DELETE}
                      </Button>}
                    {/**** DELETE FOR ADMIN ****/}
                    {isAdminTable && 
                    (
                      status===RESOURCE_STATUS.PENDING || 
                      status===RESOURCE_STATUS.PROCESS_REJECTED || 
                      status===RESOURCE_STATUS.SCRAPE_FAILED || 
                      status===RESOURCE_STATUS.S3_FAILED || 
                      status===RESOURCE_STATUS.FAILED || 
                      status===RESOURCE_STATUS.FINISHED || 
                      status===RESOURCE_STATUS.DELETE_REQUESTING) &&
                      <Button
                        className="btn-outline mr-1"
                        variant="default"
                        onClick={()=>{onAdminRemoveWebPage(page)}}>
                        {BUTTON_LABELS.BUTTON_DELETE}
                      </Button>}
                      {/***** SET SCHEDULED *****/}
                    {page.type=="PRIVATE" &&
                      <Button
                      className="btn-outline mr-1"
                      variant="default"
                      onClick={()=>{setSelectedWebPageForBatch(page)}}>
                        {BUTTON_LABELS.BUTTON_SET_SCHEDULED}
                      </Button>}
                  </></td>
                </tr>
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
    <BatchModalDialog 
        isOpen={!!selectedWebPageForBatch}
        isCreateDialog={selectedWebPageForBatch?.status===RESOURCE_STATUS.FINISHED}
        onClose={()=>{setSelectedWebPageForBatch(null)}}
        enableRecurring={true}
        onSubmit={onUpdateResourceExecution}
        initExecutionType={selectedWebPageForBatch?.execution}
        initExecutionDetail={selectedWebPageForBatch?.executionDetail}
        editable={selectedWebPageForBatch?.status!==RESOURCE_STATUS.PROCESSING}/>
    <WebRegisterDialog
    isOpen={!!selectedWebpage}
    isNew={!selectedWebpage?.id}
    onClose={()=>{setSelectedWebpage(null)}}
    url={new URL(selectedWebpage?.detailUrl || '', web.name).href}
    onRegister={onRegisterPage}/>
    <RejectResourceDialog
    isOpen={processRejectedWebpage || deleteRejectedWebpage}
    onClose={()=>{processRejectedWebpage? setProcessRejectedWebpage(null): setDeleteRejectedWebpage(null)}}
    reason={(processRejectedWebpage || deleteRejectedWebpage)?.rejectReason}
    onReject={onRejectProcess}/>
    {loading && <FanLoadingIcon size={80}/>}
  </>;
}

WebPageTable.propTypes = propTypes;
WebPageTable.defaultProps = defaultProps;

export default WebPageTable;