import {APP_TYPE, APP_TYPES} from "./constants/app.js";
import WebTable from "./pages/web/WebTable.js";
import FAQTable from "./pages/faq/FAQTable.js";
import FilePage from "./pages/file";
import QaPage from "./pages/qa/index.js";
import ChatboxSettingForm from "./pages/tag/ChatboxSettingForm.js";
import ResourcePage from "./pages/resource";
import Governments from "./pages/governments";
import {useSelector} from "react-redux";
import Account from "./pages/account";
import DashboardPage from "./pages/dashboard";

const useRoutes = () => {
    const groups = useSelector(state => state.users?.userInfo.groups);
    const isStaffGroup = groups && (groups.includes('insideAdminStaff') || groups.includes('outsideAdminStaff') || groups.includes('staff'));
    const { userInfo } = useSelector((state) => state.users)
    let routes = [
        {
            path: "/",
            layout: "/admin",
            name: "Dashboard",
            icon: "nc-icon nc-chart-pie-35",
            component: DashboardPage
        },
        APP_TYPE !== APP_TYPES.ADMIN && {
            path: "/qa_list",
            layout: "/admin",
            name: "QAリスト",
            icon: "nc-icon nc-bullet-list-67",
            component: QaPage
        },
        APP_TYPE !== APP_TYPES.INSIDE_ADMIN && APP_TYPE !== APP_TYPES.OUTSIDE_ADMIN && {
            layout: "/admin",
            name: "自治体リスト",
            icon: "nc-icon nc-vector",
            component: Governments,
            path: "/governments"
        },
        APP_TYPE !== APP_TYPES.ADMIN && {
            collapse: true,
            path: "/data_management",
            name: "データ管理",
            state: "openDataManagement",
            icon: "nc-icon nc-vector",
            views: [
                {
                    path: "/website",
                    layout: "/admin",
                    name: "WEBサイト",
                    mini: "W",
                    component: WebTable
                },
                {
                    path: "/file",
                    layout: "/admin",
                    name: "ファイル",
                    mini: "F",
                    component: FilePage
                },
                {
                    path: "/qalist",
                    layout: "/admin",
                    name: "FAQ",
                    mini: "Q",
                    component: FAQTable
                }
            ]
        },
        (APP_TYPE !== APP_TYPES.ADMIN && !isStaffGroup) && {
            path: "/data_reflection",
            layout: "/admin",
            name: "データ反映",
            icon: "nc-icon nc-vector",
            component: ResourcePage
        },
        (APP_TYPE !== APP_TYPES.ADMIN && APP_TYPE !== APP_TYPES.INSIDE_ADMIN && !isStaffGroup) && {
            path: "/chat_tag",
            layout: "/admin",
            name: "Webチャットタグ",
            icon: "nc-icon nc-tag-content",
            component: ChatboxSettingForm
        },
        (APP_TYPE !== 'ADMIN' && !isStaffGroup) && {
            path: `/governments/${userInfo.governmentId}/accounts`,
            layout: "/admin",
            name: "アカウント",
            icon: "nc-icon nc-single-02",
            component: Account
        }
    ].filter(x => x);

    return routes;
};

export default useRoutes;
