import React from 'react';
import PropTypes from 'prop-types';
import UpdateAnswerDialog from '../qa/UpdateAnswerDialog';
import FAQRegisterDialog from '../faq/FAQRegisterDialog';
import { APP_TYPE, APP_TYPES } from '../../constants/app';
import { 
  getExternalMessage, 
  getInternalMessage
} from '../../apis/MessageApi';
import { 
  updateResource,
  updateWeb
} from '../../apis/ResourceApi';
import WebRegisterDialog from '../../pages/web/WebRegisterDialog';
import { RESOURCE_STATUS } from '../../constants/status';
const propTypes={
  resource: PropTypes.object,
  onUpdatedResource: PropTypes.func,
  onClose: PropTypes.func
};
const defaultProps={
  resource: null,
  onUpdatedResource: (newResouce) => {},
  onClose: ()=>{}
};
const EditResourceDialog = (props) => {
  /*** States and Variables ***/
  const { resource, onClose, onUpdatedResource } = props;
  const [sourceMsg, setSourceMsg] = React.useState(null);
  /*** Processing ***/
  React.useEffect(()=>{
    const downloadSourceMsg = async () => {
      const srcMsgKey = JSON.parse(resource.srcMsgKey);
      const res = await (
        APP_TYPE===APP_TYPES.INSIDE_ADMIN?
        getInternalMessage(srcMsgKey.conversationId, srcMsgKey.createdAt):
        getExternalMessage(srcMsgKey.conversationId, srcMsgKey.createdAt)
      );
      if (res.success) {
        const msg = res.data;
        setSourceMsg({
          question: resource.name,
          fixAnswer: resource.answer,
          status: resource.status,
          aiAnswer: msg.text + '\n' + msg.url,
          fixUrl: resource.url,
          answerCreatedAt: resource.createdAt
        });
      }
    }
    if (resource.type==='FAQ' && resource.srcMsgKey) { 
      downloadSourceMsg(); 
    }
    else {
      setSourceMsg(null);
    }
  }, [resource])
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onUpdateFaq = async (newAnswer, newUrl) => {
    const res = await updateResource(resource, {name: sourceMsg.name, answer: newAnswer, url: newUrl});
    if (res.success) {
      onUpdatedResource(res.data);
    }
    onClose();
  }
  const onRegisterFaq = async (question, answer, url) => {
    const res = await updateResource(resource, {name: question, answer, url});
    if (res.success) {
      onUpdatedResource(res.data);
    }
    onClose();
  }
  const onRegisterWeb = async (url) => {
    const res = await updateWeb(resource, url);
    if (res.success) {
      onUpdatedResource(res.data);
    }
    onClose();
  }
  /*** Main Render ***/
  if (resource) {
    const type = resource.type;
    const status = resource.status;
    if (type==='FAQ') {
      const editable = status===RESOURCE_STATUS.PENDING || 
      status===RESOURCE_STATUS.PROCESS_REJECTED || 
      status===RESOURCE_STATUS.FAILED ||
      status===RESOURCE_STATUS.FINISHED;
      if (resource.srcMsgKey) {
        return <UpdateAnswerDialog 
        isOpen={!!sourceMsg}
        question={sourceMsg?.question}
        answer={sourceMsg?.fixAnswer}
        aiAnswer={sourceMsg?.aiAnswer}
        url={sourceMsg?.fixUrl}
        onClose={onClose}
        onUpdate={onUpdateFaq}
        editable={editable}/>
      }
      else {
        return <FAQRegisterDialog 
        isOpen={!!resource}
        faq={resource}
        onClose={onClose}
        onRegister={onRegisterFaq}
        editable={editable}/>
      }
    }
    else if (type==='WEBSITE') {
      return <WebRegisterDialog 
      isOpen={!!resource}
      isNew={!resource?.id}
      onClose={onClose}
      onRegister={onRegisterWeb}
      url={resource.name}
      editable={status===RESOURCE_STATUS.PENDING || status===RESOURCE_STATUS.PROCESS_REJECTED}/>
    }
  }
  return null;
}
EditResourceDialog.propTypes = propTypes;
EditResourceDialog.defaultProps = defaultProps;
export default EditResourceDialog;