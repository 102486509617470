import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Table,
  Col
} from "react-bootstrap";
import moment from "moment";
import { APP_TYPE, APP_TYPES } from '../../constants/app';
import MessageList from './MessageList';
import DeleteDialog from './DeleteDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown91, faArrowUp19 } from '@fortawesome/free-solid-svg-icons';
import { NUM_ITEMS_PER_PAGE } from '../../constants/table'; 
import PaginationComponent from '../../components/PaginationComponent';
import CSVDownloadDialog from './CSVDownloadDialog';
import { BUTTON_LABELS } from '../../lang/ja';

const propTypes={
  qas: PropTypes.array,
  onDownloadQas: PropTypes.func,
  onDeleteQas: PropTypes.func,
  onDownloadCsv: PropTypes.func,
  sortByDate: PropTypes.bool,
  dateAscend: PropTypes.bool,
  evalAscend: PropTypes.bool,
  onSortModeChange: PropTypes.func
};
const defaultProps={
  qas: [],
  onDownloadQas: ()=>{},
  onDeleteQas: ()=>{},
  onDownloadCsv: (month)=>{},
  sortByDate: true,
  dateAscend: false,
  evalAscend: true,
  onSortModeChange: (sortByDate, dateAscend, evalAscend)=>{}
};


const QaTable = (props) => {
  /*** States and Variables ***/
  const { qas, onDownloadQas, onDeleteQas, sortByDate, dateAscend, evalAscend, onSortModeChange, onDownloadCsv } = props;
  const [curPage, setCurPage] = React.useState(0);
  const numPages = Math.ceil(qas.length/NUM_ITEMS_PER_PAGE);
  const [selectedQa, setSelectedQa] = React.useState(null);
  const [qaResource, setDeleteDiagOpen] = React.useState(null);
  const [csvDownloadDialogOpen, setCsvDownloadDialogOpen] = React.useState(false);

  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onPageChange = (page) => {
    if (page+1===numPages) {
      onDownloadQas();
    }
    setCurPage(page);
  }

  const onDeleteQaAction = async (conversation) => {
    const deleteRes = await (onDeleteQas(qaResource));
    // TODO UX: potentially show checkmark icon upon success?
    setDeleteDiagOpen(null);
  }

  /*** Main Render ***/
  if (selectedQa) {
    return <MessageList 
    onClose={()=>{setSelectedQa(null)}}
    conversation={selectedQa}/>
  }
  return <>
    <Col md="12">
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">QA</Card.Title>
          <div className="d-flex flex-row justify-content-between align-items-end"
          style={{paddingTop: 4}}>
            <PaginationComponent 
              currentPage={curPage}
              totalItems={qas.length}
              onPageChange={onPageChange}/>
            <Button
            className="btn-outline mr-1"
            variant="default"
            onClick={()=>{setCsvDownloadDialogOpen(true)}}>
              {BUTTON_LABELS.BUTTON_DOWNLOAD}
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="table-responsive p-0">
          <Table className="table-hover table-striped w-full">
            <thead>
              <tr>
                <th>最初の質問文</th>
                <th 
                style={{
                  cursor: 'pointer',
                  color: !sortByDate? '#23CCEF': undefined}}
                onClick={()=>{onSortModeChange(false, dateAscend, !sortByDate?!evalAscend: evalAscend)}}>
                  評価
                  <FontAwesomeIcon icon={evalAscend?faArrowUp19: faArrowDown91}/>
                </th>
                {APP_TYPE===APP_TYPES.INSIDE_ADMIN && <th>ユーザー</th>}
                <th 
                style={{
                  cursor: 'pointer',
                  color: sortByDate? '#23CCEF': undefined}}
                onClick={()=>{onSortModeChange(true, sortByDate?!dateAscend: dateAscend, evalAscend)}}>
                  開始日
                  <FontAwesomeIcon icon={dateAscend?faArrowUp19: faArrowDown91}/>
                </th>
                <th>アクション</th>
              </tr>
            </thead>
            <tbody>
              {qas.map((qa, i)=>{
                if (i<curPage*NUM_ITEMS_PER_PAGE || i>=(curPage+1)*NUM_ITEMS_PER_PAGE) return null;
                return <tr key={qa.createdAt}>
                  <td>{qa.name}</td>
                  <td>{qa.evaluation && qa.evaluation[0]}</td>
                  {APP_TYPE===APP_TYPES.INSIDE_ADMIN && <td>{qa?.owner && qa.owner.username}</td>}
                  <td>{moment(qa.updatedAt).format('YYYY/MM/DD HH:mm')}</td>
                  <td><>
                    <Button
                      className="btn-outline mr-1"
                      variant="default"
                      onClick={()=>{setSelectedQa(qa)}}>
                      詳細
                    </Button>
                    {APP_TYPE===APP_TYPES.ADMIN &&
                    <Button
                      className="btn-outline mr-1"
                      variant="default"
                      onClick={()=>{setDeleteDiagOpen(qa)}}>
                      削除
                    </Button>}
                  </></td>
                </tr>
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
    
    {qaResource &&
    <DeleteDialog 
    resource={qaResource}
    title={"QA一括削除"}
    message={"このQAを全て削除します。よろしいでしょうか？"}
    onClose={()=>{setDeleteDiagOpen(null)}}
    onConfirm={()=>{onDeleteQaAction(qaResource)}}
    />}
    <CSVDownloadDialog
    isOpen={csvDownloadDialogOpen}
    onClose={()=>{setCsvDownloadDialogOpen(false)}}
    onDownload={(month)=>{onDownloadCsv(month); setCsvDownloadDialogOpen(false)}}/>
  </>;
}
QaTable.propTypes = propTypes;
QaTable.defaultProps = defaultProps;
export default QaTable;
